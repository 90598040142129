import React, { createContext, useReducer, useEffect, useState } from 'react';
import app from '../feathers';
import reducer from '../util/reducer';

const AuthenticationContext = createContext();

const initialState = {
  accessToken: null,
  user: null,
};

const AuthenticationProvider = ({ children }) => {
  const [{ accessToken, user }, dispatch] = useReducer(reducer, initialState);
  const [isTwoFactorEmailVerified] = useState(() => window.localStorage.getItem('isTwoFactorEmailVerified') === 'true');

  useEffect(() => {
    checkAuthentication();
  }, [!!accessToken]);

  const checkAuthentication = async () => {
    if (window.localStorage.getItem('tmining_wallet') !== null) {
      // console.log("Authenticating ...")
      const { accessToken, user } = await app.authenticate();
      console.log("Found", user)
      dispatch({ payload: { accessToken, user } });
    }
  };

  return (
    <AuthenticationContext.Provider value={{ accessToken, user, isTwoFactorEmailVerified }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationContext, AuthenticationProvider };
