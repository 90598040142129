import React, { useReducer, useEffect } from 'react';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import useIntl from '../hooks/useIntl';
import { useTerminals } from '../hooks/useTerminals';
import { usePorts } from '../hooks/usePorts';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(0.7),
      width: 300,
    },
    textAlign: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(2),
  },
  dropdown: {
    textAlign: 'left',
  },
  buttonStyle: {
    margin: 15,
  },
}));

const containerDiv = { overflowY: 'hidden' };
const containerForm = {
  position: 'absolute',
  top: 70,
  bottom: 80,
  left: 0,
  right: 0,
  overflow: 'auto',
};
const buttonContainer = {
  position: 'absolute',
  bottom: 20,
  left: 0,
  right: 0,
  overflow: 'hidden',
  textAlign: 'center',
};

// drop down vlues

const validityValues = ['showOnlyValid', 'showOnlyInvalid', 'showBoth', 'expiresIn1', 'expiresIn5'];
const transferStatusValues = ['pinRetrieved', 'transferred', 'owned', 'assigned'];
const releaseStatuses = ['blockedForScanning', 'blocked', 'released', 'gateout', 'expired'];

const ITEM_HEIGHT = 28;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export const FilterForm = ({ onFilterChange, showAll, filter }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const intl = useIntl();
  const classes = useStyles();
  const [terminals] = useTerminals(true);
  const [ports] = usePorts(true);

  useEffect(() => {
    dispatch({ type: 'SET_FILTER', payload: { ...filter } });
  }, [filter]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch({
      type: 'SET',
      payload: { name, value },
    });
  };

  const onApply = () => {
    // construct 'labels' object to show translations of keys (i.e. 'pintRetrieved' becomes 'Pin retrieved')
    let labels = _.pickBy(state, (f => f.length > 0));
    _.forOwn(labels, (value, key) => {
      // see if there is a translation available
      if (!!intl.messages[`${key}.${value}`]) {
        labels[key] = intl.translate(`${key}.${value}`);
      }
    })
    onFilterChange(state, labels);
  };

  const onReset = () => {
    dispatch({ type: 'RESET' });
    showAll();
  };

  return (
    <div style={containerDiv}>
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        style={containerForm}
      >
        <TextField
          id="blNumber"
          value={state.blNumber}
          name="blNumber"
          onChange={handleChange}
          label={intl.translate('releaseOverview.columns.blNumber')}
          variant="outlined"
        />
        <TextField
          id="containerNumber"
          value={state.containerNumber}
          name="containerNumber"
          onChange={handleChange}
          label={intl.translate(
            'releaseOverview.containers.columns.containerNumber'
          )}
          variant="outlined"
        />
        <TextField
          id="vessel"
          value={state.vessel}
          name="vessel"
          onChange={handleChange}
          label={intl.translate('releaseOverview.columns.vessel')}
          variant="outlined"
        />
        <TextField
          id="voyageNumber"
          value={state.voyageNumber}
          name="voyageNumber"
          onChange={handleChange}
          label={intl.translate('releaseOverview.columns.voyageNumber')}
          variant="outlined"
        />
        <TextField
          id="turnInLocation"
          value={state.turnInLocation}
          name="turnInLocation"
          onChange={handleChange}
          label={intl.translate('releaseOverview.containers.columns.turnInLocation')}
          variant="outlined"
        />
        <TextField
          id="releaseStatus"
          name="releaseStatus"
          value={state.releaseStatus}
          select
          label={intl.translate('releaseOverview.containers.columns.releaseStatus')}
          // value={value}
          className={classes.dropdown}
          onChange={handleChange}
        >
          {releaseStatuses.map((value) => (
            <MenuItem key={value} value={value}>
              {intl.translate(`releaseStatus.${value}`)}
            </MenuItem>
          ))}
        </TextField>
        
        <TextField
          id="pickupLocation"
          name="pickupLocation"
          value={state.pickupLocation}
          select
          label={intl.translate('releaseOverview.containers.columns.pickupLocation')}
          className={classes.dropdown}
          onChange={handleChange}
        >
          {terminals.map(({code}) => (
            <MenuItem key={code} value={code}>
              {code}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="pickupPort"
          name="pickupPort"
          value={state.pickupPort}
          select
          label={intl.translate('releaseOverview.containers.columns.pickupPort')}
          className={classes.dropdown}
          onChange={handleChange}
        >
          {ports.map(({name}) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="valid"
          name="valid"
          value={state.valid}
          select
          label="Valid"
          // value={value}
          className={classes.dropdown}
          onChange={handleChange}
        >
          {validityValues.map((value) => (
            <MenuItem key={value} value={value}>
              {intl.translate(`filterForm.valid.${value}`)}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="transferStatus"
          name="transferStatus"
          value={state.transferStatus}
          select
          label={intl.translate('releaseOverview.containers.columns.transferStatus')}
          // value={value}
          className={classes.dropdown}
          onChange={handleChange}
        >
          {transferStatusValues.map((value) => (
            <MenuItem key={value} value={value}>
              {intl.translate(`transferStatus.${value}`)}
            </MenuItem>
          ))}
        </TextField>
      </form>
      <div style={buttonContainer}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onApply}
          className={classes.buttonStyle}
        >
          {intl.translate('releaseOverview.containers.columns.applyButton')}
        </Button>
        <Button variant="outlined" color="primary" onClick={onReset}>
          {intl.translate('releaseOverview.containers.columns.resetButton')}
        </Button>
      </div>
    </div>
  );
};

const initialState = {
  blNumber: '',
  containerNumber: '',
  vessel: '',
  voyageNumber: '',
  releaseStatus: '',
  pickupLocation: '',
  pickupPort: '',
  turnInLocation: '',
  valid: 'showOnlyValid',
  transferStatus: '',
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET':
      // eslint-disable-next-line no-case-declarations
      const { name, value } = payload;
      return { ...state, [name]: value };

    case 'SET_FILTER':
      return { ...initialState, ...payload };

    case 'RESET':
      return { ...initialState };

    default:
      return state;
  }
};
