import React from 'react';

import { withJsonFormsControlProps } from '@jsonforms/react';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { rankWith, isControl } from '@jsonforms/core';
import { Grid, IconButton, TextField } from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { isEmpty } from 'lodash';

function UploadFile(props) {
  const { path, handleChange, data, enabled } = props;
  const disabled = !enabled;

  const [files, setFiles] = React.useState(data || []);

  React.useEffect(() => handleChange(path, files), [files]);

  const handleFileAdd = (newFiles) => {
    setFiles((prev) => [
      ...prev,
      ...newFiles.map(({ file, data }) => ({
        name: file.name,
        ref: undefined,
        content: data,
      })),
    ]);
  };

  const handleFileDelete = (deletedFileName) => {
    setFiles((files) => files.filter((f) => f.name !== deletedFileName));
  };

  const handleFileRefs = (fileName, e) => {
    const _files = [...files];
    let file = _files.find((f) => f.name === fileName);
    file.ref = e.target.value;
    setFiles(() => _files);
  };

  const handleFileDownload = (file) => {
    const link = document.createElement('a');
    link.href = file.content;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <React.Fragment>
      <DropzoneAreaBase
        dropzoneProps={{ disabled, noClick: disabled, noDrag: disabled }}
        onAdd={handleFileAdd}
      />
      {files.length > 0 ? (
        <Grid container spacing={2} style={{ marginBottom: '1em' }}>
          <Grid item xs={12} sm={1} style={{ paddingTop: '1.5em' }}>
            File info
          </Grid>
          <Grid item xs={12} sm={11}>
            {files.map((file, index) => (
              <Grid container spacing={2} key={`grid-${index}`}>
                <Grid item sm={5} style={{ paddingTop: '1.5em' }}>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    tabIndex={0}
                    onClick={() => {
                      handleFileDelete(file.name);
                    }}
                    disabled={disabled}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    aria-label="download"
                    size="small"
                    tabIndex={1}
                    onClick={() => {
                      handleFileDownload(file);
                    }}
                    disabled={disabled}
                  >
                    <DownloadIcon />
                  </IconButton>
                  {file.name}
                </Grid>
                <Grid item sm={7} style={{ paddingTop: '1.2em' }}>
                  <TextField
                    value={file.ref}
                    size="small"
                    placeholder="Add file info or comment"
                    fullWidth
                    onChange={(e) => handleFileRefs(file.name, e)}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : null}
    </React.Fragment>
  );
}

export const tester = () => (uischema) => {
  const { options } = uischema;
  if (isEmpty(options) || !isControl(uischema)) {
    return false;
  }

  return options?.format == 'file';
};

export const uploadFileTester = rankWith(
  3, //increase rank as needed
  tester()
);

export default withJsonFormsControlProps(UploadFile);
