import React, { createContext, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const ImprovedDialogContext = createContext();

const ImprovedDialogProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [{ title, content, actions, canClose }, setDialog] = useState({});

  const showDialog = (s) => setShow(s);

  return (
    <ImprovedDialogContext.Provider value={{ setDialog, showDialog }}>
      {children}
      <Dialog open={show} maxWidth='sm' fullWidth={true} onClose={() => showDialog(false)}>
        <DialogTitle>
          {title}
          {canClose &&
            <IconButton
              aria-label="close"
              onClick={()=>{showDialog(false)}}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#999',
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    </ImprovedDialogContext.Provider>
  );
};

export { ImprovedDialogContext, ImprovedDialogProvider };
