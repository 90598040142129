import '../style.css';

import React from 'react';
import TMDataTable from '../../lib/Table/TMDataTable';
import useIntl from '../../hooks/useIntl';
import { AddBox as AddIcon } from '@material-ui/icons';
import { AddDocument } from './AddDocument';
import { formatDate } from '../../util/date';
import { useDocuments } from './hooks/useDocuments';
import { Menu, IconButton, MenuItem } from '@material-ui/core';
import { useDocumentTypes } from './hooks/useDocumentTypes';
import DocumentShareDialog from './hooks/useDocumentShareDialog';
import { useDocumentShare } from './hooks/useDocumentShare';
import useAuth from '../../hooks/useAuth';
import SplitButton from '../SplitButton';

export function Documents() {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const [selectedDocs, setSelectedDocs] = React.useState([]);
  const [initialData, setInitialData] = React.useState({});
  const [formMeta, setFormMeta] = React.useState({
    title: '',
    documentTypeId: null,
    schema: {},
    uiSchema: {},
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { translate } = useIntl();
  const { data = [], ...documentService } = useDocuments();
  const { data: documentTypes } = useDocumentTypes();
  const shareDocument = useDocumentShare();
  const { user } = useAuth();

  const handleSubmit = async ({
    id = null,
    documentTypeId,
    attachments,
    ...body
  }) => {
    await documentService.post(
      { id, documentTypeId, body, attachments },
      {
        onSuccess: () => {
          documentService.refetch();
          setOpenDialog(false);
        },
      }
    );
  };

  const handleDocumentClick = ({
    documentType,
    initialData,
    readonly = false,
  }) => {
    const { id, formDefinition, schema, uiSchema, title } = documentType;

    setFormMeta(() => ({
      documentTypeId: id,
      title,
      formDefinition,
      schema,
      uiSchema,
      readonly,
    }));
    setInitialData(() => initialData);
    setOpenDialog(true);
  };

  const handleDocumentShareClick = (data) => {
    setSelectedDocs(() => [data?.id]);
    setOpenShareDialog(true);
  };

  const handleShareDocumentSubmit = (data) => {
    shareDocument.post(data, {
      onSuccess: () => {
        documentService.refetch();
        setOpenShareDialog(false);
      },
    });
  };

  const handleToBeAcceptedDoc = async (id, status) => {
    await documentService.patch(
      id,
      { status, organizationAddress: user.organizationAddress },
      {
        onSuccess: () => {
          documentService.refetch();
        },
      }
    );
  };

  const columns = [
    {
      name: 'body',
      label: 'type',
      options: {
        customBodyRender: ({ documentType }) => documentType.title,
      },
    },
    {
      name: 'body',
      label: 'reference',
      options: {
        customBodyRender: (body) => body.reference,
      },
    },
    {
      name: 'status',
      label: 'status',
      options: {
        customBodyRender: (status) => (
          <span className="capitalize">{status}</span>
        ),
      },
    },
    {
      name: 'createdAt',
      label: 'creationDate',
      options: {
        customBodyRender: (date) => formatDate(date),
      },
    },
    {
      name: 'organization',
      label: 'createdBy',
      options: {
        customBodyRender: (organization) => organization?.name,
      },
    },
    {
      name: 'body',
      label: 'options',
      options: {
        customBodyRender: (
          { documentType, status, ...initialData },
          metaData
        ) => {
          const organizationAddress = metaData.rowData[4].address;
          const owner = organizationAddress == user.organizationAddress;

          const options = [
            {
              label: 'View/Edit',
              onClick: () => {
                handleDocumentClick({
                  documentType,
                  initialData,
                  readonly: !owner,
                });
              },
              isValid: true,
            },
            {
              label: 'Share',
              onClick: () => handleDocumentShareClick(initialData),
              isValid: status === 'draft',
            },
            {
              label: 'Accept',
              onClick: () => handleToBeAcceptedDoc(initialData.id, 'accepted'),
              isValid: status === 'to be accepted' && !owner,
            },
            {
              label: 'Reject',
              onClick: () => handleToBeAcceptedDoc(initialData.id, 'rejected'),
              isValid: status === 'to be accepted' && !owner,
            },
          ].filter((option) => option.isValid);

          return <SplitButton options={options} />;
        },
        filter: false,
        sort: false,
      },
    },
  ].map(({ label, ...rest }) => ({
    label: translate(`documents.${label}`),
    ...rest,
  }));

  const options = {
    print: false,
    download: false,
    selectableRows: 'single',
    searchAlwaysOpen: true,
    customToolbar: () => (
      <React.Fragment>
        <IconButton
          color="primary"
          aria-label="document"
          aria-controls="docs-menu"
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <AddIcon />
        </IconButton>

        <Menu
          id="docs-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {documentTypes?.map((documentType) => (
            <MenuItem
              key={documentType.id}
              onClick={() =>
                handleDocumentClick({ documentType, initialData: {} })
              }
            >
              {documentType?.title}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    ),

    textLabels: {
      body: {
        noMatch: translate('dataTable.controls.noMatch'),
      },
    },
  };

  const addDocumentProps = {
    initialData,
    openDialog,
    setOpenDialog,
    handleSubmit,
    ...formMeta,
  };

  return (
    <div className="no-radius-table">
      <AddDocument {...addDocumentProps} />
      <TMDataTable columns={columns} data={data} options={options} />
      <DocumentShareDialog
        open={openShareDialog}
        selectedDocs={selectedDocs}
        onCancel={() => setOpenShareDialog(false)}
        onShare={handleShareDocumentSubmit}
      />
    </div>
  );
}
