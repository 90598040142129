import React, { useReducer, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import TMDialog from '../../lib/Dialog/TMDialog';
import TMForm from '../../lib/Form/TMForm';
import useIntl from '../../hooks/useIntl';
import { useProgressDialog } from '../../context/ProgressDialogProvider';
import { useAlertDialog } from '../../context/AlertDialogProvider';
import client from '../../feathers';
import useSentry from '../../hooks/useSentry';
import { addtoUserAuditTrail } from '../../util/userAuditTrail';

const AddTransporterDialog = ({ type, openAdd, handleDialogClose }) => 
{

	const { translate } = useIntl();
	const [state, dispatch] = useReducer(reducer, initialState);
	const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { enqueueSnackbar } = useSnackbar();
  const logSentry = useSentry();
  const { showAlert } = useAlertDialog();

	const fields = {
		barge: [
			{ field: 'name', label: 'Name', required: true }, 
			{ field: 'identification', label: 'ENI number', required: true, minLength: 8, maxLength: 8  }, 
    ],
    driver: [
			{ field: 'firstName', label: 'First name', required: true }, 
			{ field: 'lastName', label: 'Last name', required: true }, 
			{ field: 'email', label: 'Email', isEmail: true },
			{ field: 'phone', label: 'Phone', type: 'tel' },
			{ field: 'identification', label: 'AlfaPass Nbr', required: true, type: 'number', minLength: 5, maxLength: 6 },
    ],
    train: [
      { field: 'name', label: 'Name', required: true }, 
      { field: 'identification', label: 'Train code', required: true  }, 
    ],
	}

	const handleChange = (e) => {
    let obj = { ...state[type] };
    obj[e.target.name] = (e.target.hasOwnProperty('checked') ? e.target.checked : e.target.value);

    dispatch({ payload: { 
    	[type]: { ...obj }
    }})
  };

  const handleAdd = async () => {
    let didNotError = true;
    try {
    	showProgressDialog();
    	// validation
      const { isNew } = await client.service(`${type}s`).create(state[type]);
      dispatch({ type: 'RESET' });
      enqueueSnackbar(translate(isNew ? `${type}.created` : `${type}.alreadyExists`), { autoHideDuration: 1500 });
      addtoUserAuditTrail(`add ${type}`, state[type])
    } catch (error) {
      logSentry(error);
    	showAlert(translate("general.error"), error.message);
    	didNotError = false;
    } finally {
      handleDialogClose(didNotError);
      hideProgressDialog();
    }
	}

	return (
		<TMDialog
      key={`add-${type}`}
      title={translate('transporters.add', {type})}
      dialogOpen={openAdd}
      handleDialogClose={handleDialogClose}
      maxWidth="md"
      showBottomClose={false}
    >
      <TMForm
        object={state[type]}
        handleChange={handleChange}
        handleSubmit={handleAdd}
        submitLabel={translate('transporters.add', {type})}
        handleCancel={handleDialogClose}
        cancelLabel={translate("general.cancel")}
        fields={fields[type]}
      />
    </TMDialog>
	)
}

const initialState = {
	barge: {
    identification: '',
	  name: '',
	},
	driver: {
    identification: '',
	  firstName: '',
		lastName: '',
		email: '',
		phone: '',
	},
  train: {
    identification: '',
    name: '',
  },
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET':
      return { ...initialState }
    default:
      // = SET
      return { ...state, ...payload }
  }
}

export default AddTransporterDialog;