import logSentry from '../util/logSentry';
import { addtoUserAuditTrail } from '../util/userAuditTrail';
const { AuthenticationClient } = require('@feathersjs/authentication-client');

class WalletAuthenticationClient extends AuthenticationClient {

  async authenticate() {
    const authResult = await this.getAuthentication();
    return authResult;
  }

  async getAuthentication() {
    let result = {};
    try {
      const { challenge } = await getChallengeFromBackend(); // backend wallet

      try {
        let walletInfo = window.walletApi._getWalletInfo();
        let signature = await window.walletApi.challenge(challenge); // frontend wallet

        if (walletInfo && walletInfo.type === 'company') {
          signature.id = walletInfo.id;
        }

        // SCRDEV-811: track nbr of logins and releases
        const connections = await window.walletApi.getConnections();

        result = await super.authenticate({
          strategy: 'wallet',
          signature,
          addresses: connections.map(c => c.local.address).filter(c => !!c)
        });

      } catch (e) {
        console.debug(e);
        // ignore for SCRP-217
      }
    } catch (error) {
      logSentry(error);
      throw error;
    }

    if (result.hasOwnProperty('accessToken') && result.hasOwnProperty('user')) {
      const { organizationAddress, termsAcceptedAt, invitedByMsc } = result.user;
      window.sessionStorage.setItem('org', JSON.stringify({ organizationAddress, termsAcceptedAt, invitedByMsc }));
      window.sessionStorage.setItem('jwt-accessToken', result.accessToken);
      addtoUserAuditTrail('login', { id: result.user.id, email: result.user.email });
    } else {
      // throw new Error('Could not get token');
      return { accessToken: undefined, user: undefined };
    }

    return result;
  }
}

// POST to the /authentication endpoint from your backend
// note that we only pass { strategy: 'wallet' } in the body here
const getChallengeFromBackend = async () => {
  const result = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/authentication/`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        strategy: 'wallet',
      }),
    }
  );

  const response = checkStatus(result);
  return response.json();
};

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  try {
    const result = response.json();
    throw new Error(result.message);
  } catch (err) {
    throw new Error({
      message: response.statusText,
      url: response.url,
    });
  }
};

export default WalletAuthenticationClient;
