import React from 'react';
import { Box } from '@material-ui/core';
import useIntl from '../../../hooks/useIntl';
import OrganizationSelectionTable from '../../OrganizationSelectionTable';
import { useConnectedOrganizations } from '../../../hooks/useConnectedOrganizations';
import TMDialog from '../../../lib/Dialog/TMDialog';

const DocumentShareDialog = ({
  open,
  selectedDocs,
  onShare = () => {},
  onCancel = () => {},
}) => {
  const [selectedOrganization, setSelectedOrganization] = React.useState(null);
  const { translate } = useIntl();
  const { data } = useConnectedOrganizations();

  const { contact, connectedOrganizations } = data || {};

  const onOrganizationSelect = (selectedOrganization) =>
    setSelectedOrganization(() => selectedOrganization);

  return (
    <TMDialog
      key="share document"
      title={translate(`documents.share`)}
      dialogOpen={open}
      handleDialogClose={() => {
        setSelectedOrganization(null);
        onCancel();
      }}
      showBottomClose
      btnLabel={translate('documents.share')}
      handleSubmit={() => onShare({ selectedOrganization, selectedDocs })}
      actionBtnClasses="flex justify-space-between w-full"
      actionBtnVariant="contained"
    >
      <div>
        {translate(`documents.share.dialog.line_1`, {
          numberOfDocs: selectedDocs.length || 0,
        })}

        <Box my="1rem">
          <OrganizationSelectionTable
            organizations={connectedOrganizations}
            onSelect={onOrganizationSelect}
          />
          <p>{translate(`transferRevoke.transfer.dialog.line_2`, contact)}</p>
        </Box>
      </div>
    </TMDialog>
  );
};

export default DocumentShareDialog;
