import React, { useState, useReducer, useEffect } from 'react'
import { useSnackbar } from 'notistack';
import { pickBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import TMForm from '../lib/Form/TMForm';
import client from '../feathers';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import Switch from '@material-ui/core/Switch';
import useIntl from '../hooks/useIntl';
import TMDataTable from '../lib/Table/TMDataTable';
import TMDialog from '../lib/Dialog/TMDialog';
import { useProgressDialog } from '../context/ProgressDialogProvider';
import { useAlertDialog } from '../context/AlertDialogProvider';
import useConfirmDialog from '../hooks/useConfirmDialog';
import useSentry from '../hooks/useSentry';
import useAuth from '../hooks/useAuth';
import AddTransporterDialog from '../components/releases-transporters/AddTransporterDialog.jsx';
import BarChartIcon from '@material-ui/icons/BarChart';
import { useSuccessRateOfSecurePickup } from '../hooks/useSuccessRateOfSecurePickup';
import { addtoUserAuditTrail } from '../util/userAuditTrail';

const useStyles = makeStyles((theme) => ({
  hoverButton: {
    color: '#757575',
    '&:hover': {
    	color: theme.palette.primary.main
    }
  },
  inactive: {
    '& td': {
      color: theme.palette.secondary.main,
    }
  },
}));

export default function BargesOverview() {
	
	const [openAdd, setOpenAdd] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();
  const { showAlert } = useAlertDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { translate } = useIntl();
	const classes = useStyles();
	const logSentry = useSentry();
  const { user } = useAuth();
  const  { successRateOfSecurePickupJsx, openSrsPickupDialog } = useSuccessRateOfSecurePickup('barges');

	const fetchBarges = async () => {
		const result = await client.service('barges').find();
	  dispatch({ payload: { barges: result.data } })
  };

  useEffect(() => {
    fetchBarges();
  }, []);

	const columns = [
		{ name: 'Name' },
		{ name: 'ENI number' },
		{ name: 'Action', options: { filter: false, download: false, viewColumns: false } },
		{ name: "isActive", options: { filter: false, download: false, display: false, viewColumns: false } }
	];

	const openEditBarge = async (barge) => {
    dispatch({ payload: {
    	name: barge.name,
		  identification: barge.identification,
		  isActive: barge.isActive,
			organizationAddress: barge.organization_address,
			id: barge.id,
    }})
    setOpenEdit(true);
  };

  const handleDialogClose = async () => {
    await fetchBarges();
    dispatch({ type: 'RESET' });
  	openEdit ? setOpenEdit(false) : setOpenAdd(false);
  }

	const handleChange = (e) => {
    dispatch({ payload: { 
    	[e.target.name]: (e.target.hasOwnProperty('checked') ? e.target.checked : e.target.value) 
    }})
  };

  const checkEdit = async () => {
  	const currentBarge = state.barges.find(b => b.id === state.id);
  	if ((!state.isActive && currentBarge.isActive) || (state.identification !== currentBarge.identification)) {
  		showConfirmDialog('');
  	} else {
  		handleEditBarge();
  	}
  }

	const handleEditBarge = async () => {
    let didNotError = true;
    try {
    	showProgressDialog();
			const data = {
				name: state.name,
				eniNumber: state.identification,
				isActive: state.isActive
			}
			const result = await client.service('barges').patch(state.id, data);
			const currentBarge = state.barges.find(b => b.id === state.id);

			addtoUserAuditTrail(
				'edit barge', 
				{ 
					id: state.id, 
					old: pickBy(currentBarge, (v,k) => (data.hasOwnProperty(k) && data[k] !== v)), // only add the changed values 
					new: pickBy(data, (v,k) => currentBarge[k] !== v) // only add the changed values
				}
			);

    	// check if barge was inactivated or eniNumber number changed
    	if ((!state.isActive && currentBarge.isActive) || (state.identification !== currentBarge.identification)) {
    		enqueueSnackbar(translate('barge.edit.warning.unassigning'), { persist: true });
    		await window.walletApi.unassignReleases(
          result.removedReleases.map(({release}) => ({ address: release.address, version: release.version })),
          { from: user.organization }
        );
      	closeSnackbar();
    	}
  		enqueueSnackbar(translate('barge.patched'), { autoHideDuration: 1500 });
    } catch (error) {
      logSentry(error);
    	showAlert(translate('general.error'), error.message, "sm");
    	didNotError = false;
    } finally {
      didNotError && await fetchBarges();
      handleDialogClose();
      hideProgressDialog();
    }
	}

	const { showConfirmDialog } = useConfirmDialog(
		'barge.edit', 'barge.edit.warning', 
		handleEditBarge, handleDialogClose,
		'general.ok', 'general.cancel'
	)

	const setRowProps = (row, dataIndex) => {
    // last field = isActive
    if(row[row.length-1] === false) return { className: classes.inactive }
  };

	const getData = () => {
		return state.barges.map(b => [
			b.name, 
			b.identification, 
			<IconButton className={classes.hoverButton} onClick={() => openEditBarge(b)}><EditIcon/></IconButton>,
			b.isActive
		])
	}

	return (
		<React.Fragment>
			<div className="barges-table">
				<TMDataTable
		      title={'Barges'}
		      columns={columns}
		      data={getData()}
		      setRowProps={setRowProps}
		      options={{
			      print: false,
			      filter: false,
			      viewColumns: false,
				  customToolbar: () => (
					<React.Fragment>
					  <IconButton
						className={classes.hoverButton}
						onClick={() => openSrsPickupDialog()}
						>
						<BarChartIcon />
					  </IconButton>
					  <IconButton
					  className={classes.hoverButton}
					  onClick={() => setOpenAdd(true)}
					  >
					  <CreateIcon />
					  </IconButton>
					</React.Fragment>
				),
			     
		      }}
		    />
	    </div>

			<AddTransporterDialog
				type={'barge'} 
				openAdd={openAdd} 
				handleDialogClose={handleDialogClose}
			/>

	    <TMDialog
	      key="edit-barge"
	      title={"Edit barge"}
	      dialogOpen={openEdit}
	      handleDialogClose={handleDialogClose}
	      maxWidth="md"
	      showBottomClose={false}
	    >
	      <TMForm
	        object={state}
	        handleChange={handleChange}
	        handleSubmit={checkEdit}
	        submitLabel={translate("barge.edit")}
	        handleCancel={handleDialogClose}
	        cancelLabel={translate("general.cancel")}
	        fields={[
	    			{ field: 'name', label: 'Name', required: true }, 
	    			{ field: 'identification', label: 'ENI number', required: true, type: 'number', minLength: 8, maxLength: 8  }, 
            { field: 'isActive', label: 'Active ?', componentType: Switch },
	        ]}
	      />
	    </TMDialog>
		{ successRateOfSecurePickupJsx }
		</React.Fragment>
	);
}

const initialState = {
  barges: [],
  name: '',
  identification: '',
  isActive: false,
  organizationAddress: '',
  id: 0,
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET':
      return { ...initialState, barges: state.barges }
    default:
      // = SET
      return { ...state, ...payload }
  }
}
