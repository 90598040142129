import React, { useEffect, useState } from 'react';
import client from '../feathers';

export const useTerminals = (plain=false) => {
  const [state, setState] = useState([]);

  useEffect(() => {
  	const fetchTerminals = async () => {
	    const result = await client.service('terminals').find();
	    setState(plain 
	    	? result
	    	: result.map(t => {
			      return { 
			        value: t.code, 
			        label: t.code, 
			        target: { 
			          name: 'pickupLocation', 
			          value: t.code
			        }
			      }
			    })
	  	);
	  };
    fetchTerminals();
  }, []);

	return [state];
};