import { useEffect, useState } from 'react';
import client from '../feathers';

export const useDrivers = (query) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    const fetchDrivers = async (query) => {
      const result = await client.service('drivers').find({ query });
      setState(result.data);
    };
    fetchDrivers(query);
  }, []);

  return state;
};
