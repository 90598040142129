import { removeSpecialCharacters } from '../util/release';

// This filters on anything BUT validity
const useLocalFilter = (doGrouping) => {
  
  const makeFilterExpression = (data, filter) => {
    // The bolFilter may be empty, when only a filter on containerfields has been used. 
    // So check if any field has been used and if not, just return true and carry on with the full filter object
    if (Object.values(filter).filter(value => value !== "").length === 0) {
      return true;
    }

    // Go through the filter fields
    return Object.entries(filter)
      .map(([key, value]) => {
        // if a filter fields has a value
        if (value) {
          // match it to the corresponding field (using key') in the data
          return removeSpecialCharacters(data[key]).toLowerCase().includes(removeSpecialCharacters(value).toLowerCase())
        } else {
          return false;
        }
      })
      // the result is an array of booleans - check if it contains at least one 'true'
      .includes(true);
  };

  const applyFilter = (data, filter) => {
    if (!Object.keys(filter).length) {
      return data;
    }

    const {
      blNumber,
      containerNumber,
      vessel,
      voyageNumber,
      turnInLocation,
      releaseStatus,
      pickupLocation,
      ediName: pickupPort, // Translate 'pickupPort' to the actual attribute names
      // validity is skipped here. this is the LOCAL filter, validity filter goes to the backend
      transferStatus,
    } = filter;

    const bolFilter = {
      blNumber,
      vessel,
      voyageNumber,
    };

    if (doGrouping) {
      return data
        .filter(bill =>
          // Filter BLs on the bolFilter only.
          // If you use the full filter object here, and only container fields are used ('turnInLocation' for example), 
          // this will return all false already, resulting in an empty result array for this filter method
          makeFilterExpression(bill, bolFilter)
        )
        .map(bill => {
          let containers = bill.containers?.filter(container => makeFilterExpression(container, filter));
          // So you found some BLs by 'vessel' for instance, but no containers by 'turnInLocation' for example.
          // Then we don't return the BL at all (= 'and' way of thinking, not 'or')
          if (!containers?.length) { return; }
          else { return { ...bill, containers }; } // we override the whole containers array in the BL with just the filtered ones
        })
        .filter(_ => _);
    } else {
      // a lot simpler here, just apply the while filter-object on the non-nested data-object
      return data
        .filter(container => makeFilterExpression(container, filter))
        .filter(_ => _);
    }
  };
 
  return applyFilter;
};

export default useLocalFilter;
