import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/AddBox';
import TMDataTable from '../../../lib/Table/TMDataTable';
import useIntl from '../../../hooks/useIntl';
import AddTransporterDialog from '../AddTransporterDialog.jsx';

const useStyles = makeStyles((theme) => ({
  hoverButton: {
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

const ReleasesTrains = ({ trains, onSelect, handleVisitNumber, selectFirstRow, prefilledVisitNumber, refreshData }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const classes = useStyles();
  const { translate } = useIntl();

  const columns = ['name', 'traincode'].map((column) => translate(`train.${column}`));
  
  const data = trains
    .filter(t => t.isActive)
    .map(({ name, trainCode }) => 
      [name, trainCode]
    );

  const handleChange = (e) => {
    handleVisitNumber(e.target.value)
  }

  const handleDialogClose = async (doRefresh=false) => {
    if (doRefresh) { 
      await refreshData('trains');
    }
    setOpenAdd(false);
  }

  const options = {
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20],
    selectableRowsOnClick: true,
    selectFirstRow,
    sort: false,
    print: false,
    filter: false,
    search: true,
    searchOpen: true,
    download: false,
    viewColumns: false,
    selectableRows: 'single',
    onSelectionChange: ([selected]) => {
      if (selected) {
        const identification = selected.pop();
        onSelect(identification);
      } else {
        onSelect(undefined)
      } 
    },
    customToolbar: () => {
      return (<IconButton className={classes.hoverButton} onClick={() => setOpenAdd(true)}><CreateIcon/></IconButton>)
    },
    textLabels: {
      body: {
        noMatch: translate('dataTable.controls.noMatch'),
      },
    },
  };

  return (
    <div className="no-radius-table">

      <AddTransporterDialog
        type={'train'} 
        openAdd={openAdd} 
        handleDialogClose={handleDialogClose}
      />

      <TMDataTable columns={columns} data={data} options={options} />
      <Grid container spacing={2} style={{marginTop: '1em'}}>
        <Grid item xs={2} style={{margin: 'auto'}}>{translate('transporters.assign.visitNumber')}</Grid>
        <Grid item xs={10}>
          <TextField onChange={handleChange} variant="outlined" value={prefilledVisitNumber}/>
          <br/><small style={{color:'#aaa'}}>If applicable, please enter the visit number here</small>
        </Grid>
      </Grid>
    </div>
  )
};

export default ReleasesTrains;
