import React from 'react';

import { useCookies } from 'react-cookie';

function CookieBanner({ position = 'left' }) {
  const [, setCookie] = useCookies(['cookieAccepted']);

  const reloadWindow = () => window.location.reload(1);

  const handleAccept = () => {
    setCookie('cookieAccepted', true, { path: '/' });
    reloadWindow();
  };

  const handleDecline = () => {
    setCookie('cookieAccepted', false, { path: '/' });
    reloadWindow();
  };

  return (
    <div className={`cookie-banner cookie-banner-${position}`}>
      <p>
        By using this website, you agree to our use of cookies. We use cookies
        to provide you with a great experience and to provide the chat function
        for our support team.
      </p>
      <button onClick={handleAccept}>OK</button>
      <button onClick={handleDecline}>Decline</button>
    </div>
  );
}

export default CookieBanner;
