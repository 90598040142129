import React from 'react';
import useIntl from '../hooks/useIntl';
import { parseError } from '../util/release'

const AssignmentReport = ({ data, operation }) => {
  const { translate } = useIntl();
  // operation = assign | unassign
  return (
    <div>
      <p>{translate(`assignmentReport.${operation}.summary`)}</p>
      {
        Object.entries(data).map(([key, value]) => {
          return value.length === 0
            ? null 
            : <div key={key}>
                {translate(`assignmentReport.${operation}.${key}`)}
                <ul>
                  {value.map((release, i) => {
                    return  <li key={i}>
                              {release.container ? release.container.containerNumber : release.address} { key === 'walletErrors' && ` : ${release.error}` }
                            </li>
                  })}
                </ul>
              </div> 
        })
      }
    </div>
  );
};

export default AssignmentReport;
