import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InfoIcon from '@material-ui/icons/Info';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { green, grey, red, yellow } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import useIntl from '../hooks/useIntl';

const useStyles = makeStyles((theme) => ({
  typography: {
    float: 'right',
    marginRight: '20px',
    fontSize: 'small'
  },
  gldocs: {
    textAlign: 'center',
    backgroundColor: 'rgba(162, 219, 184, 0.4)',
    margin: '30px 20px 20px',
    padding: '10px',
    width: '250px',
    fontSize: '0.9em'
  },
  listItemText: {
    fontSize: '1em',
  },
  smallText: {
    fontSize: '0.75em'
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '0.5em',
    cursor: 'pointer'
  }
}));

export default function GreenLightsPopover({ text, data }) {
  const eventOrder = ['commercialrelease', 'terminaloperation', 'terminalready', 'customs', 'customsprogress', 'gateoperation', 'pickuplight']
  
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { translate } = useIntl();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // Saving the object to the db on the backend randomizes the order somehow, sorting needs to be done on the frontend
    let sortedEvents = {};
    eventOrder.forEach(e => sortedEvents[e] = data.events[e]);
    // remove missing lights for the object, otherwise the Popover crashes, expecting "{ value, color, updatedAt }"
    Object.entries(sortedEvents).forEach(([key, value]) => {
      if (value === undefined) {
        delete sortedEvents[key];
      }
    });
    data.events = sortedEvents;
  }, [data])

  const getColor = (val) => {
    switch(val) {
      case 'yellow': 
        return yellow[400]; 
        break;
      case 'green': 
        return green[400]; 
        break;
      case 'gray': 
        return grey[400]; 
        break;
      default: 
        return red[400]
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div style={{display: 'flex'}}>
      <FiberManualRecordIcon style={{ color: getColor(data.total) }} onClick={handleClick} />
      <Typography aria-describedby={id} style={{fontSize: '0.875rem'}}>
        {text}
      </Typography>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <React.Fragment>
          <List style={{paddingBottom: '0px'}}>
            <div className={classes.closeIcon} onClick={handleClose}>
              <CloseIcon />
            </div>
            {
              Object.entries(data.events).map(([event, { value, color, updatedAt } ]) => {
              return (
                <ListItem
                  key={event}
                  style={{ paddingTop: '2px', paddingBottom: '2px' }}
                >
                  <ListItemIcon style={{ minWidth: '40px' }}>
                    <Tooltip title={translate(`greenlights.tooltip.${event}.${value}`)} arrow>
                      <InfoIcon style={{ color: getColor(color) }}/>
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    primary={translate(`greenlights.event.${event}`) + ": " + translate(`greenlights.value.${value}`)}
                    secondary={updatedAt !== '' && `Last update: ${updatedAt}`}
                    classes={{ primary: classes.listItemText, secondary: classes.smallText }}
                  />
                </ListItem>
              )
            })
          }
          </List>
          <Typography className={classes.typography}>
            Source: {data.source}
          </Typography>
          <Typography className={classes.gldocs}>
            {translate('greenlights.popover.infotxt')}<br/> 
            <b><a href="https://www.securecontainerrelease.com/green-lights" target="_new">{translate('greenlights.popover.infolink')}</a></b>
          </Typography>
        </React.Fragment>
      </Popover>
    </div>
  );
}
