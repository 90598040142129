import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Icon from '@mdi/react'
import { mdiClipboardList as BulkOperationsIcon } from '@mdi/js';
import useIntl from '../../hooks/useIntl';
import BulkOperationsDialog from './BulkOperationsDialog';

const BulkOperationsButton = ({ doTransfer, user, refreshBills }) => {
  
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { translate } = useIntl();

  // SCRDEV-430 - Check if there was a scroll
  var scrollY = 0, TIMER = null;
  const getPos = () => {
    const rect = document.getElementById('BulkOperationsButton').getBoundingClientRect();
    if (scrollY === 0) {
      scrollY = rect.top;
    } else {
      if (scrollY !== rect.top) {
        hideHighLight();
        clearInterval(TIMER);
      }
    }
  }

  useEffect(() => {
    // SCRDEV-430 - Check if there was a scroll
    TIMER = setInterval(getPos, 100);
    return () => clearInterval(TIMER);
  }, []);

  const showHighLight = () => {
    setAnchorEl(document.getElementById('BulkOperationsButton'));
  }

  const hideHighLight = () => {
    setAnchorEl(null);
  }

  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={hideHighLight}>
        <Popper style={{zIndex: 1301}} id={anchorEl ? undefined : 'popper'} open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top-end"
                className={ classNames({ ['animate__animated animate__fadeIn animate__faster']: (anchorEl !== null) }) }>
          <Paper style={{padding: '10px', backgroundColor: '#5C9EAD', color: '#fff'}}>
            <span style={{paddingLeft: '15px', paddingRight: '15px'}}>Would you like to retrieve pins or transfer releases in <b>BULK</b>? Try here.</span>
            <ArrowDownwardIcon className={classNames({ ['animate__animated animate__bounce animate__slow']: (anchorEl !== null) })}/>
          </Paper>
        </Popper>
      </ClickAwayListener>

      <Tooltip title={translate('bulkoperations.tooltip')}>
        <IconButton onClick={() => { window.addToDataLayer('bulk-operations', 'open'); setOpen(true)}} className='showPremium'
                    id="BulkOperationsButton" aria-label="Bulk Operations" aria-describedby={anchorEl ? undefined : 'popper'} >
          <Icon 
            path={BulkOperationsIcon} 
            size={1} /*color={anchorEl ? '#5C9EAD' : '#EE5658'} */
            className={ classNames(
              { ['animate__animated animate__heartBeat animate__repeat-3 animate__delay-1s']: (anchorEl !== null) }
            )}
          />
        </IconButton>
      </Tooltip>

      <BulkOperationsDialog
        doTransfer={doTransfer}
        refreshBills={refreshBills}
        user={user}
        open={open}
        handleDialogClose={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default BulkOperationsButton;
