import React, { useEffect, useReducer } from 'react'
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import TMForm from '../lib/Form/TMForm';
import client from '../feathers';
import useIntl from '../hooks/useIntl';
import { useAlertDialog } from '../context/AlertDialogProvider';
import useSentry from '../hooks/useSentry';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/Help';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import TMPaper from '../lib/Paper/TMPaper';
import useAuth from '../hooks/useAuth';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import * as Subscriptions from '../util/subscriptions';

const moment = require('moment');

const useStyles = makeStyles((theme) => ({
	formRow: {
    display: 'flex', 
    alignItems: 'top', 
    marginBottom: '1em'
  },
  label: {
    display: 'flex',
    marginTop: '1em',
    fontFamily: 'Roboto,"Helvetica Neue",Arial,sans-serif',
  },
  smallText: {
    color: theme.palette.secondary.dark,
    display: 'block'
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
  stepper: {
  	margin: '2em -2em',
  	backgroundColor: '#eee',
  },
  secondaryLabel: {
    display: 'flex',
    marginTop: '0.7em',
    fontFamily: 'Roboto,"Helvetica Neue",Arial,sans-serif',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontSize: '0.9em',
    fontWeight: 'bold'
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    marginTop: '1em'
  },
}));

export default function OrderPremium() {
	
	const { translate } = useIntl();
  const { showAlert } = useAlertDialog();
	const classes = useStyles();
	const logSentry = useSentry();
	const { user } = useAuth();
  const history = useHistory();
	const [state, dispatch] = useReducer(reducer, initialState);

	// Prefill form with current organization
	useEffect(() => {
    const preFill = async() => {
	    try {
		    const result = await client.service('organizations').find({ 
		    	query: { 
		    		address: user.organizationAddress,
		    		countUsers: true, 
		    	} 
		    });
		    dispatch({ payload: { 
		    	selectedOrganizations: result,
		    	selectedOrganizationsDropdown: makeSelectedOrganizationsDropdown(result),
		    	invoiceOrg: result[0].address,
		    	invoiceFrequencyOptions: [
	    			{ label: translate('order.premium.invoicefreq.label.monthly'), value: 'monthly', helperText: translate('order.premium.invoicefreq.helper.monthly') }, 
  					{ label: translate('order.premium.invoicefreq.label.yearly'), value: 'yearly', helperText: translate('order.premium.invoicefreq.helper.yearly') }
					]
		    } })
		  } catch (error) {
		  	logSentry(error);
	    	showAlert(translate("general.error"), error.message);
		  }
		}
		user && preFill();
  }, []);

	// Auto-calculate the fee when sleected organizations list changes
  useEffect(() => {
  	calculateFee();
  }, [state.selectedOrganizations, state.scrFlavor, state.locations])

  const handleSearchOrganization = async () => {
    try {
	    const result = await client.service('organizations').find({ 
	    	query: {
			    field: state.searchField,
			    countUsers: true, 
			  }
	    });
	    dispatch({ payload: { 
	    	organizations: result,
	    	searchResultText: result.length === 0 ? 'dataTable.controls.noMatch' : undefined
	    }})
	  } catch (error) {
	  	logSentry(error);
    	showAlert(translate("general.error"), error.message);
	  }
  };

	const handleChange = (e) => {
    dispatch({ payload: { 
    	[e.target.name]: (e.target.type === 'checkbox' ? e.target.checked : e.target.value),
    }})
  };

  const handleLocations = (e) => {
  	dispatch({ payload: { 
    	locations: (e.target.checked ? [...state.locations, e.target.name] : state.locations.filter(location => location !== e.target.name)),
    }})
  }

	const handleCheck = (address) => {
  	let selectedOrganizations = [];
  	const isAlreadyAdded = state.selectedOrganizations.find(o => o.address === address)
  	if (!isAlreadyAdded) {
  		selectedOrganizations = [...state.selectedOrganizations, state.organizations.find(o => o.address === address)];
	  } else {
	  	selectedOrganizations = state.selectedOrganizations.filter(o => o.address !== address);
	  }
	  
		dispatch({ payload: { 
			selectedOrganizations, 
			selectedOrganizationsDropdown: makeSelectedOrganizationsDropdown(selectedOrganizations)
		}})
  }

  const calculateFee = () =>
	{
		// Thank you chatGPT: https://chatgpt.com/share/66fc0133-02b4-8007-88fc-f138547c771a
		function calculateBestPrice(numOrganizations, usersPerOrganization, numCountries) {
		  // Definieer organisatiegrootte op basis van aantal gebruikers
		  function getOrganizationSize(numUsers) {
		    if (numUsers <= 2) return 'small';
		    if (numUsers <= 10) return 'medium';
		    return 'enterprise'; // Meer dan 10 users
		  }

		  // Prijs per organisatie per land voor de Single formule
		  const singlePrices = {
		    small: 19,
		    medium: 49,
		    enterprise: 199,
		  };

		  // Prijs per organisatie voor alle 4 de landen voor de Global formule
		  const globalPrices = {
		    small: 49,
		    medium: 129,
		    enterprise: 499,
		  };

		  // Prijs voor maximaal 3 organisaties per land voor de Multi formule
		  const multiPrices = {
		    small: 99,
		    medium: 199,
		    enterprise: 599, // Als er meer dan 1 organisatie is, waarvan minstens 1 enterprise, geldt deze prijs.
		  };

		  // Functie om Single formule prijs te berekenen
		  function calculateSinglePrice() {
		    let totalPrice = 0;
		    usersPerOrganization.forEach(users => {
		      const size = getOrganizationSize(users); // Bepaal de grootte op basis van aantal gebruikers
		      totalPrice += singlePrices[size] * numCountries; // Voor elke organisatie, vermenigvuldig de prijs per land met het aantal landen
		    });
		    return totalPrice;
		  }

		  // Functie om Global formule prijs te berekenen
		  function calculateGlobalPrice() {
		    let totalPrice = 0;
		    usersPerOrganization.forEach(users => {
		      const size = getOrganizationSize(users);
		      totalPrice += globalPrices[size]; // Prijs voor de organisatie voor alle 4 landen
		    });
		    return totalPrice;
		  }

		  // Functie om Multi formule prijs te berekenen
		  function calculateMultiPrice() {
		    let totalPrice = 0;

		    const hasEnterprise = usersPerOrganization.some(users => getOrganizationSize(users) === 'enterprise');

		    if (numOrganizations > 1 && hasEnterprise) {
		      // Als er meer dan 1 organisatie is en er is een enterprise, gebruik dan enterprise prijs per land
		      totalPrice = multiPrices['enterprise'] * numCountries;
		    } else {
		      usersPerOrganization.forEach(users => {
		        const size = getOrganizationSize(users);
		        totalPrice += multiPrices[size] * numCountries;
		      });
		    }

		    return totalPrice;
		  }

		  // Bereken de prijs voor elk van de drie formules
		  const singlePrice = calculateSinglePrice();
		  const globalPrice = calculateGlobalPrice();
		  const multiPrice = calculateMultiPrice();

		  // Zoek de goedkoopste prijs
		  const bestPrice = Math.min(singlePrice, globalPrice, multiPrice);

		  // Geef de goedkoopste prijs terug
		  return {
		    bestPrice,
		    prices: {
		      single: singlePrice,
		      global: globalPrice,
		      multi: multiPrice,
		    }
		  };
		}

		const numOrganizations = state.selectedOrganizations.length;
		const usersPerOrganization = state.selectedOrganizations.map(o => o.usercount);
		const numCountries = state.locations.length;

		const result = calculateBestPrice(numOrganizations, usersPerOrganization, numCountries);
		console.log('Best price:', result.bestPrice);
		console.log('Price per formula:', result.prices);

	  dispatch({ payload: { lowestFee: result.bestPrice }});
	}

	const makeSelectedOrganizationsDropdown = (list) => {
  	return list.map(o => ({
			value: o.address, 
      label: `${o.name} (${o.vat})`, 
      target: { 
        name: 'invoiceOrg', 
        value: o.address
      }
		}))
  }

	const handleOrder = async () => {
  	try {
	  	// save entry in db
	  	await client.service('orders').create({
	  		organizationAddresses: state.selectedOrganizations.map(o => o.address),
	  		flavor: state.scrFlavor,
	  		locations: state.locations,
	  		fee: state.lowestFee,
	  		invoiceOrg: state.invoiceOrg,
	  		invoiceEmail: state.invoiceEmail,
	  		invoiceFrequency: state.invoiceFrequency,
	  		po: state.po,
	  		userId: user.id,
	  		// this is used to trigger the 'marketing emails'
	  		organizationIds: state.selectedOrganizations.map(o => o.id)
	  	})
  		await dispatch({ payload: { activeStep: state.activeStep+1 } })
  	} catch (error) {
  		logSentry(error);
    	if (error.code === 401) {
    		showAlert("Session expired. You are being redirected to the login page. Please log in again.");
    		localStorage.clear();
	      sessionStorage.clear();
	      setTimeout(() => { window.location.reload() }, 2500);

	      ;
    	} else {
    		showAlert(translate("general.error"), error.message);
    	}
  	}
  }

  const getStartDate = () => {
  	// Startdate = when current trial subscription ends. If no current trial subscription present, then startDate = today
    const now = moment();
    return (
    	<ul style={{marginTop: 0, paddingLeft: '15px'}}> {
    		state.selectedOrganizations.map(o => {
    			let endDate; 
    			if (o.premium_enddate === null || moment(o.premium_enddate).isSameOrBefore(now, 'day')) { 
    				endDate = 'Next business day';
    			} else {
    				let dayIncrement = 1;
    				// check for weekends
  					if (moment(o.premium_enddate).day() === 5) {
    					// 5 = friday, set to monday
    					dayIncrement = 3;
  					} else if (moment(o.premium_enddate).day() === 6) {
    					// 6 = saturday, set to monday
    					dayIncrement = 2;
  					}
    			 	endDate = moment(o.premium_enddate).add(dayIncrement, 'day').format('ddd DD MMM');
					}
		 			return (
		 				<li>
		 					{o.name}: {endDate} 
		 					<span style={{color:'#666', marginLeft: '5px'}}>(
		 						{ o.premium_enddate === null 
		 							? 'No current Premium subscription'
		 							: `End date current premium subscription: ${moment(o.premium_enddate).format('ddd DD MMM')}`
								}
		 					)</span> 
	 					</li>
 					)
		 		})
    	}
    	</ul>
  	)
  }

  const handleCancel = () => {
		window.location.assign("/")
	}

	return (
		<Grid container>
			<Grid item xs={12} lg={8} style={{margin: '0 auto'}}>

			{ state.activeStep === 0 &&
				<TMPaper title={ translate("order.premium.pagetitle") }>
					<p>
						{translate("order.premium.intro.1")}<br/>
						{translate("order.premium.intro.2")}<br/>
						{translate("order.premium.intro.3")}
					</p>

					{ /* SELECTED ORGANIZATIONS */ }
				  <Grid container spacing={2} className={classes.formRow} style={{display: state.selectedOrganizations.length ? 'flex' : 'none'}}>

				  	<Grid item xs={12} sm={2} className={classes.secondaryLabel}>
              YOUR ORGANIZATIONS
            </Grid>
           	<Grid item xs={12} sm={10}>
              <Divider className={classes.divider}/>
              <small className={classNames(classes.smallText, classes.primaryText)} style={{marginTop: '5px'}}>
                These are the selected organizations that will be upgraded to SCR Premium
             	</small>
           	</Grid>

				  	<Grid item xs={12} sm={2} className={classes.label}>
		        	Selected organization(s)
		        </Grid>
		        <Grid item xs={12} sm={10}> 
		        	<List style={{width:'500px'}}> 
			      		{
					      	state.selectedOrganizations.map(o => {
					      		return (
						      		<ListItem key={o.address} role={undefined} dense style={{paddingLeft: 0}}>
					            	<ListItemIcon>
				            		{ 
				            			o.address !== user.organizationAddress 
				            			? <IconButton onClick={() => handleCheck(o.address)} style={{marginLeft: '-15px'}}>
							              	<DeleteIcon />
							              </IconButton>
							            : <HomeIcon />
					            	}
				            		</ListItemIcon>
						            <ListItemText id={`checkbox-selected-org-${o.address}`} primary={o.name} secondary={`${o.street_nbr}, ${o.postal_code} ${o.city}, ${o.country}`}/>
						            <ListItemSecondaryAction> 
				              		<Chip icon={<GroupIcon/>} label={o.usercount}/>
					            	</ListItemSecondaryAction>
						          </ListItem>
						        )
					      	})
					      }
				      </List>
				      { 
				      	!state.showSearch &&
				      	<Button variant="contained" color="primary" onClick={() => { dispatch({payload: {showSearch: true}}) }}>
				      		{ translate('order.premium.lookup.title') }
				      	</Button>
			      	}
		        </Grid>
				  </Grid>

				  <Grid container spacing={2} className={classes.formRow} style={{display: state.showSearch ? 'flex' : 'none'}}>
		  			<Grid item xs={12} sm={2} className={classes.secondaryLabel}>
              { translate("order.premium.lookup.title") }
            </Grid>
           	<Grid item xs={12} sm={10}>
              <Divider className={classes.divider}/>
              <small className={classNames(classes.smallText, classes.primaryText)} style={{marginTop: '5px'}}>
                { translate("order.premium.lookup.helper") }
             	</small>
           	</Grid>
          </Grid>
				  <Grid container spacing={2} className={classNames(classes.formRow, { ['animate__animated animate__zoomIn animate__faster']: state.showSearch })} style={{display: state.showSearch ? 'flex' : 'none'}}>
		    		<Grid item xs={12} sm={2} className={classes.label}>
				  		Search Name / VAT
						</Grid>
		        <Grid item xs={12} sm={10}> 
		        	<TextField 
		        		name="searchField" label="Name / VAT" variant="outlined" value={state.searchField} required 
	        			onChange={handleChange} helperText={translate("order.premium.lookup.helper")} style={{width:'500px'}}
	        			InputProps={{
		        			endAdornment:
			              <InputAdornment position="end">
			                <IconButton onClick={handleSearchOrganization}>
			                  <SearchIcon />
			                </IconButton>
			              </InputAdornment>
		            }}
        			/>
        			<p>
	        			<Button variant="contained" color="primary" onClick={handleSearchOrganization}>
				      		SEARCH
			      		</Button>
			      		&nbsp;
	        			<Button variant="outlined" color="secondary" onClick={() => { dispatch({payload: {showSearch: false, organizations: [], searchField: ''}}) }}>
				      		{ translate('order.premium.lookup.hide') }
			      		</Button>
			      	</p>
			      </Grid>
				  	{ /* SEARCH RESULTS */ }
		    		<Grid item xs={12} sm={2} className={classes.label}>
		    			{ state.organizations.length > 0 && <span>Search results</span> }
						</Grid>
		        <Grid item xs={12} sm={10}>
		        	{ state.searchResultText && <p>{translate(state.searchResultText)}</p> }
		        	<List style={{width:'500px'}}> 
		      		{
				      	state.organizations.map(o => {
				      		return (
					      		<ListItem key={o.address} role={undefined} dense button disabled={o.address === user.organizationAddress} onClick={() => handleCheck(o.address)} style={{paddingLeft: 0}}>
					            <ListItemIcon>
					              <Checkbox color="primary" edge="start" checked={state.selectedOrganizations.map(s => s.address).indexOf(o.address) !== -1} />
					            </ListItemIcon>
					            <ListItemText id={`checkbox-found-org-${o.address}`} primary={o.name} secondary={`${o.street_nbr}, ${o.postal_code} ${o.city}, ${o.country}`}/>
					            <ListItemSecondaryAction>
					              <Chip icon={<GroupIcon/>} label={o.usercount}/>
					            </ListItemSecondaryAction> 
					          </ListItem>
					        )
				      	})
				      }
				      </List>
				    </Grid>
					</Grid>	

		    	{ /* PRICING INFO */ }
				  <Grid container spacing={4} className={classes.formRow}>

				  	<Grid item xs={12} sm={2} className={classes.secondaryLabel}>
              { translate("order.premium.pricing.title") }
            </Grid>
		      	<Grid item xs={12} sm={10}>
            	<Divider className={classes.divider}/>
            	<small className={classNames(classes.smallText, classes.primaryText)} style={{marginTop: '5px'}}>{translate("order.premium.pricing.helper")}</small>
            </Grid>

		      	<Grid item xs={12} sm={2} className={classes.label}>
		        	Select your location(s) *
		        </Grid>
		        <Grid item xs={12} sm={10}> 
        			<FormGroup>
	        			<FormControlLabel
		        			control={ <Checkbox checked={state.locations.includes('Belgium')} onChange={handleLocations} name="Belgium" color="primary"/> }
		        			label="Belgium"
		      			/>
		      			<FormControlLabel
		        			control={ <Checkbox checked={state.locations.includes('Netherlands')} onChange={handleLocations} name="Netherlands" color="primary"/> }
		        			label="Netherlands"
		      			/>
		      			<FormControlLabel
		        			control={ <Checkbox checked={state.locations.includes('Poland')} onChange={handleLocations} name="Poland" color="primary"/> }
		        			label="Poland"
		      			/>
		      			<FormControlLabel
		        			control={ <Checkbox checked={state.locations.includes('UK')} onChange={handleLocations} name="UK" color="primary"/> }
		        			label="United Kingdom (UK)"
		      			/>
		      		</FormGroup>
		      	</Grid>

		      	<Grid item xs={12} sm={2}>
		        	{ translate("order.premium.label.price") } &nbsp;
		        	<Tooltip title={ translate("order.premium.label.price.info") }>
		        		<a href="https://www.securecontainerrelease.com/scr-premium" target="_blank" rel="noopener noreferrer">
		        			<HelpIcon fontSize="small" color="secondary"/>
		      			</a>
		    			</Tooltip>
		        </Grid>
		        <Grid item xs={12} sm={10}> 
			        <p style={{fontWeight: 'bold', fontSize: '1.1em'}}>
			        {
			        	state.locations.length 
				        	? `${state.lowestFee} EUR / month`
				        	: "Please select your location(s) first"
			        }
			        </p>
		      	</Grid>
		      	
		      	<Grid item xs={12} sm={2}>
		        	{ translate("order.premium.label.startDate") }
		        </Grid>
		        <Grid item xs={12} sm={10}> 
		        	{ getStartDate() }
		      	</Grid>

		      </Grid>
		    	{ /* INVOICE INFO */ }
		      <TMForm
		        object={state}
		        handleChange={handleChange}
		        handleSubmit={handleOrder}
		        submitLabel={ translate("order.premium.confirm") }
		        handleCancel={handleCancel}
		        cancelLabel={ translate("order.premium.cancel") }
		        enableSubmitCondition={!!state.scrFlavor && state.locations.length && !!state.invoiceOrg && !!state.invoiceEmail && state.eulaAccepted}
		        fields={[
		        	{ field: '_divider', label: translate('order.premium.invoicing.title'), helperText: translate('order.premium.invoicing.helper') }, 
		        	{ field: 'invoiceOrg', label: translate('order.premium.label.invoice.org'), required: true, fullWidth: true, 
								componentType: Select, suggestions: state.selectedOrganizationsDropdown, placeholder: translate("order.premium.invoice.select") },
              { field: 'invoiceEmail', label: translate("order.premium.label.invoiceemail"), required: true, isEmail: true, fullWidth: true, helperText: translate("order.premium.invoice.email") },
              { field: 'po', label: translate("order.premium.label.po"), fullWidth: true },
              { field: 'invoiceFrequency', componentType: Radio, label: translate("order.premium.label.invoicefrequency"), 
              	required: true, fullWidth: true, suggestions: state.invoiceFrequencyOptions },
							{ field: 'eulaAccepted', label: translate("order.premium.label.eula"), componentType: Switch, fullWidth: true, required: true,
                placeholder: translate("order.premium.label.eula.accept"),
                helperText: <a href='https://www.securecontainerrelease.com/scr-app-terms-eula' target='_new'>{ translate("order.premium.label.eula.read") }</a> 
              },
		        ]}
		      />
		  	</TMPaper>
    	}

			{ state.activeStep === 1 &&

				<TMPaper title={ translate("order.premium.thanks.title") }>
					<p>
						{translate("order.premium.thanks.body.1")}<br/>
						{translate("order.premium.thanks.body.2")}</p>
					<p>
						{translate("order.premium.thanks.greeting")}<br/>
						{translate("order.premium.thanks.signature")}</p>
					<p>
						<Button variant="outlined" color="primary" onClick={handleCancel}>
		      		{ translate('navbar.overview') }
		      	</Button></p>
				</TMPaper>
	    }
	  	</Grid>
	  </Grid>
  )
}

const initialState = {
	showSearch: false,
  organizations: [],
  selectedOrganizations: [],
  selectedOrganizationsDropdown: [],
  searchField: '',
  searchResultText: undefined,
  scrFlavor: 'SCR Premium', // temporary default, other options are hidden
  locations: [],
  lowestFee: undefined,
  activeStep: 0,
  invoiceOrg: '',
  invoiceEmail: '',
  invoiceFrequency: 'monthly',
  invoiceFrequencyOptions: [],
  po: undefined,
  eulaAccepted: false
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'RESET':
      return { ...initialState }
    default:
      // = SET
      return { ...state, ...payload }
  }
}
