import React from 'react';
import client from '../../../feathers';
import useCarrierApiError from '../../carrier-ui/hooks/useCarrierApiError';

export function useDocuments() {
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState([]);
  const onCarrierApiError = useCarrierApiError();

  const fetch = async () => {
    try {
      const documents = await client.service('documents').find();
      setData(() => {
        return documents?.data.map(
          ({ id, documentType, status, body, attachments, ...rest }) => ({
            ...rest,
            id,
            status,
            body: { id, documentType, status, ...body, attachments },
          })
        );
      });
    } catch (error) {
      setError(error);
    }
  };

  const post = async (data, { onSuccess = () => {} }) => {
    try {
      await client.service('documents').create(data);
      onSuccess();
    } catch (error) {
      setError(error);
      onCarrierApiError(error);
    }
  };

  const patch = async (id, data, { onSuccess = () => {} }) => {
    try {
      await client.service('documents').patch(id, data);
      onSuccess();
    } catch (error) {
      setError(error);
      onCarrierApiError(error);
    }
  };

  React.useEffect(() => {
    fetch();
  }, []);

  return { data, error, refetch: fetch, post, patch };
}
