import React, { useRef } from 'react';
import { Button, Box } from '@material-ui/core';
import useImprovedDialog from './useImprovedDialog';
import useIntl from './useIntl';

const useSecurePickupDialog = ({ onTransfer, onAssign, onPincode, onWithoutPincode }, userCanFetchPincode) => {

  const { translate } = useIntl();
  const { setDialog, showDialog } = useImprovedDialog();
  const data = useRef();

  const actions = (
    <Box style={{margin: "0 auto 10px"}}>
      
      { onTransfer &&
        <Button
          style={{marginRight:'20px'}}
          variant="contained"
          color="primary"
          onClick={() => {
            showDialog(false);
            onTransfer(data.current);
          }}
        >
          { translate('blActions.transfer') }
        </Button>
      }
      { onAssign &&
        <Button
          style={{marginRight:'20px'}}
          variant="contained"
          color="secondary"
          onClick={() => {
            showDialog(false);
            onAssign(data.current);
          }}
        >
          { translate('blActions.assign') }
        </Button>
      }
      
      { onPincode && userCanFetchPincode &&
        <Button
          style={{marginRight:'20px'}}
          variant="outlined"
          color="secondary"
          onClick={() => {
            showDialog(false);
            onPincode(data.current);
          }}
        >
          { translate('blActions.pincode') }
        </Button>
      }

      { onWithoutPincode &&
        <Button
          style={{marginRight:'20px'}}
          variant="contained"
          color="primary"
          onClick={() => {
            showDialog(false);
            onWithoutPincode(data.current);
          }}
        >
          { translate('getpincode.fetch.skip') }
        </Button>
      }
      
      <Button
        style={{marginRight:'20px'}}
        variant="outlined"
        color="primary"
        onClick={() => {
          showDialog(false);
        }}
      >
        { translate('general.cancel') }
      </Button>
    </Box>
  );

  const showSecurePickupDialog = (d) => {
    setDialog({
      title: translate('pincode.confirm.title'),
      content: <iframe title='securePickupFrame' scrolling="auto" style={{borderStyle: 'none', width: '100%', height: '620px'}} id="securePickuprame" src="https://www.securecontainerrelease.com/iframe-secure-pickup-mandatory" />,
      actions,
      canClose: true
    });
    data.current = d;
    showDialog(true);
  };

  return { showSecurePickupDialog };
};

export default useSecurePickupDialog;
