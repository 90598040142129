import React from 'react'
import useIntl from '../../hooks/useIntl'
import { parseError } from '../../util/release'

const BatchUploadReport = ({ data }) => {
	// console.log(data)
	const { translate } = useIntl();
	const validationErrors = data.validationResults.filter(res => res.errorText);
	const createSuccesses = data.createResults.filter(res => res.statusText)
	const createErrors = data.createResults.filter(res => res.errorText)

	return (
		<div>
			{ 
				createSuccesses.length > 0 && 
				<p>{translate('fileUpload.report.success')}</p>
			}
			<ul>
			{ 
				createSuccesses.map((result, i) => {
					let transferResult = "";
					if (data.transferResults) {
						transferResult = data.transferResults.eligible_releases.find(r => r.address === result.data.address)
						if (transferResult === undefined) {
							transferResult = data.transferResults.errors.find(r => r.address === result.data.address)
							if (transferResult) {
								transferResult = `, transfer failed (${parseError(transferResult.error)})`
							}
						} else {
							transferResult = translate('carrierui.multi.transfer.ok')
						}
					}
					return (
						<li key={i}>
							BL {result.data.billOfLading.blNumber}, CTR {result.data.container.containerNumber}:
							&nbsp; 
							{result.statusText} { transferResult }
						</li>
					)
				})
			}
			</ul>
			{
				createErrors.concat(validationErrors).length > 0 && 
				<p>{translate('fileUpload.report.error')}</p>
			}
			<ul>
			{
				createErrors.concat(validationErrors).map((result, i) => {
					return (
						<li key={i}>
							BL {result.data.billOfLading.blNumber}, CTR {result.data.container.containerNumber}:
							&nbsp; 
							{result.errorText}
						</li>
					)
				})
			}
			</ul>
		</div>
	)
}

export default BatchUploadReport

