import React from 'react';
import { Http } from '../../axios';

export function useTwoFactorAuthentication() {
  const [data, setData] = React.useState({ twoFactorAuthEmailValidated: false });
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const sendEmail = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const response = await Http.get(`/two-factor/auth/email`);
      setData(() => response?.data);
      setIsLoading(false)
    } catch (error) {
      setError(() => error.message);
      setIsLoading(false)
    }
  };

  const validate = async (data) => {
    try {
      setError(null);
      setIsLoading(true);
      const response = await Http.post(`/two-factor/auth/email/validate`, data);
      setData(() => response?.data);
    } catch (error) {
      console.error('Error validating 2FA code:', error.message);
      setError(() => error.message);
      setIsLoading(false)
    }
  };

  return { data, error, sendEmail, validate, isLoading, setError };
}
