import React from 'react';
import client from '../../../feathers';
import useCarrierApiError from '../../carrier-ui/hooks/useCarrierApiError';

export function useDocumentShare() {
  const [data, setData] = React.useState({});
  const [error, setError] = React.useState([]);
  const onCarrierApiError = useCarrierApiError();

  const post = async (
    { selectedDocs, selectedOrganization } = {},
    { onSuccess = () => {} }
  ) => {
    try {
      const [docId] = selectedDocs;
      const targetOrganizationAddress = selectedOrganization.address;
      const response = await client
        .service('document-share')
        .create({ docId, targetOrganizationAddress });
      setData(() => response?.data);
      onSuccess();
    } catch (error) {
      console.error(error);
      setError(error);
      onCarrierApiError(error);
    }
  };

  return { data, error, post };
}
