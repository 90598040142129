// import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';


import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
Sentry.configureScope(function(scope) {
	scope.setTag("environment", "production");
	scope.setTag("source", "frontend")
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
