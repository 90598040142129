import React, { useEffect, useState } from 'react';
import client from '../feathers';

export const usePorts = (plain=false) => {
  const [state, setState] = useState([]);

  useEffect(() => {
  	const fetchPorts = async () => {
	    const result = await client.service('ports').find({ 
	    	query: { $sort: { name: 1 } } 
	    });
	    setState(plain 
	    	? result.data
	    	: result.data.map(p => {
			      return { 
			        value: p.name, 
			        label: p.name, 
			        target: { 
			          name: 'pickupPort', 
			          value: p.name
			        }
			      }
			    })
	  	);
	  };
    fetchPorts();
  }, []);

	return [state];
};