import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { useTwoFactorAuthentication } from './useTwoFactorAuthentication';
import useAuth from '../../hooks/useAuth';
import useIntl from '../../hooks/useIntl';
import TMForm from '../../lib/Form/TMForm';
import TMPaper from '../../lib/Paper/TMPaper';
import { TWO_FACTOR_AUTH } from '../../util/twoFactorAuth';
import { useProgressDialog } from '../../context/ProgressDialogProvider';
import { useSnackbar } from 'notistack';
import { logout } from '../../util';

export default function TwoFactorAuthentication() {
  const [verificationCode, setVerificationCode] = useState('');
  const { translate } = useIntl();
  const { user, isTwoFactorEmailVerified } = useAuth();
  const twoFactorAuthentication = useTwoFactorAuthentication();
  const { enqueueSnackbar } = useSnackbar();
  const { showProgressDialog, hideProgressDialog } = useProgressDialog();

  const sendVerificationEmail = async () => {
    console.log("2FA sendVerificationEmail")
    await twoFactorAuthentication.sendEmail();
  };

  useEffect(() => {
    if (!user) return;

    if (user.twoFactorAuth !== TWO_FACTOR_AUTH.emailEnabled) return;
    if (isTwoFactorEmailVerified) return;

    // Check if email already sent
    if (window.localStorage.getItem('isAlready2FaEmailSent') == 'true') return;
    sendVerificationEmail();
    window.localStorage.setItem('isAlready2FaEmailSent', 'true');
  }, [user, isTwoFactorEmailVerified]);

  useEffect(() => {
    const handleVerificationResponse = () => {
      if (twoFactorAuthentication.data.twoFactorAuthEmailValidated) {
        window.localStorage.setItem('isTwoFactorEmailVerified', 'true');
        window.localStorage.removeItem('isAlready2FaEmailSent');
        window.location.reload(1);
      }
    };

    handleVerificationResponse();
  }, [twoFactorAuthentication.data.twoFactorAuthEmailValidated]);

  useEffect(() => {
    if (twoFactorAuthentication.error) {
      enqueueSnackbar(twoFactorAuthentication.error, {
        variant: 'error',
        autoHideDuration: 3000,
        onClose: () => {
          twoFactorAuthentication.setError(() => '');
        },
      });
    }
  }, [twoFactorAuthentication.error]);

  const handleChange = (e) => {
    const { value } = e.target;
    setVerificationCode(value);
  };

  const handleSubmit = async () => {
    await twoFactorAuthentication.validate({ code: verificationCode });
  };

  const handleResendEmail = async () => {
    showProgressDialog();
    await sendVerificationEmail();
    hideProgressDialog();
    enqueueSnackbar(`Verification code sent successfully`, {
      variant: 'success',
      autoHideDuration: 3000,
      style: { maxWidth: '420px' },
    });
  };

  if (user.twoFactorAuth !== TWO_FACTOR_AUTH.emailEnabled) {
    return <Redirect push to={`/`} />;
  }

  if (
    user.twoFactorAuth === TWO_FACTOR_AUTH.emailEnabled &&
    isTwoFactorEmailVerified
  ) {
    return <Redirect push to={`/`} />;
  }

  return (
    <TMPaper title="Two-Factor Authentication">
      <TMForm
        object={{ code: verificationCode }}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        submitLabel="Verify"
        enableSubmitCondition={true}
        actions={[
          {
            handle: handleResendEmail,
            label: 'Re-send code',
            variant: 'outlined',
            color: 'primary'
          },
        ]}
        cancelLabel="Cancel"
        handleCancel={logout}
        fields={[
          {
            field: 'code',
            label: 'Enter 2FA Code',
            required: true,
            fullWidth: true,
            helperText: translate(
              '6-digit code has been sent to your registered email'
            ),
          },
        ]}
      />
      <p>
        <b>Important:</b> <br/>
        Two factor authentication emails are sent from <b>twofactor@securecontainerrelease.com</b>. <br/>
        In case you're not receiving these emails, please check your spam folder and whitelist the emailaddress.
      </p>
    </TMPaper>
  );
}
