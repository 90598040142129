import React from 'react';

import { isEmpty } from 'lodash';

import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, isControl } from '@jsonforms/core';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import TextField from '@material-ui/core/TextField';

function OutlinedDate(props) {
  const { label, path, handleChange, data, enabled } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label={label}
        inputFormat="MM/DD/YYYY"
        value={data || ''}
        onChange={(event) => handleChange(path, event)}
        readOnly={!enabled}
        renderInput={(params) => {
          return (
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              {...params}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}

export const tester = () => (uischema) => {
  const { options } = uischema;
  if (isEmpty(options) || !isControl(uischema)) {
    return false;
  }

  return options?.format == 'outlinedDate';
};

export const outlinedDateTester = rankWith(
  3, //increase rank as needed
  tester()
);

export default withJsonFormsControlProps(OutlinedDate);
