import React from 'react';
import { IntlProvider } from 'react-intl';
import { SnackbarProvider } from 'notistack';

import Layout from './pages/Layout';
import messages_en from './translations/en.json';
import messages_fr from './translations/fr.json';
import messages_nl from './translations/nl.json';
import message_de from './translations/de.json';
import message_it from './translations/it.json';
import TMWithRoot from './lib/Theme/TMWithRoot';
import { ProgressBarProvider } from './context/ProgressBarProvider';
import { LanguageProvider, LanguageContext } from './context/LanguageProvider';
import { AuthenticationProvider } from './context/AuthenticationProvider';
import { DrawerProvider } from './context/DrawerProvider';
import { DialogProvider } from './context/DialogProvider';
import { ImprovedDialogProvider } from './context/ImprovedDialogProvider';
import { ProgressDialogProvider } from './context/ProgressDialogProvider';
import { AlertDialogProvider } from './context/AlertDialogProvider';

import * as Sentry from "@sentry/react";

const languages = {
  de: message_de,
  en: messages_en,
  fr: messages_fr,
  it: message_it,
  nl: messages_nl,
};

const App = () => {
  console.log("REACT_APP_NODE_ENV", process.env.REACT_APP_NODE_ENV);
  return (
    <Sentry.ErrorBoundary>
    <AuthenticationProvider>
      <ProgressBarProvider>
        <LanguageProvider>
          <LanguageContext.Consumer>
            {(value) => (
              <IntlProvider
                locale={value.language}
                messages={languages[value.language]}
              >
                <DrawerProvider>
                  <DialogProvider>
                    <ImprovedDialogProvider>
                      <ProgressDialogProvider>
                        <AlertDialogProvider>
                          <SnackbarProvider maxSnack={5} hideIconVariant preventDuplicate>
                            <Layout />
                          </SnackbarProvider>
                        </AlertDialogProvider>
                      </ProgressDialogProvider>
                    </ImprovedDialogProvider>
                  </DialogProvider>
                </DrawerProvider>
              </IntlProvider>
            )}
          </LanguageContext.Consumer>
        </LanguageProvider>
      </ProgressBarProvider>
    </AuthenticationProvider>
    </Sentry.ErrorBoundary>
  );
};

export default TMWithRoot(App);
