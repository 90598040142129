import React, { useEffect } from 'react';
import classNames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import WithToolTip from './WithTooltip';

export default ({ disabledBtn, options, isSecurePickup, doGrouping }) => {

  const useStyles = makeStyles((theme) =>
    createStyles({
      splitButton: {
        fontSize: '0.75rem',
        width: '192px',
        borderRightWidth: doGrouping ? '0px !important' : '', // for some reason, the border remains in tact in the BL view but not in the CTR view
      },
      isSecurePickup: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        }
      },
      isNotAllSecurePickup: {
        backgroundColor: '#FFFFFF',
        border: `${disabledBtn ? '': `solid 1px ${theme.palette.primary.main}`}`,
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
        }
      },
    })
  );

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  useEffect(() => {
    // SCRDEV-279: show unassign when available
    // SCRDEV-405: undo SCRDEV-279
    setSelectedIndex(options.findIndex((o) => !o.disabled));
  }, [options]);
  
  /**
   *
   * @param {Event} event
   * @param {Number} index
   */
  const handleMenuItemClick = (event, index) => {
    // Remember: in case of `isSecurePickup = true`, the first option is just a label ('Pick-up via SCR'), which is filtered out of the Popper.
    // So we need to adjust the index by 1 here
    if (isSecurePickup) {
      index = index + 1;
    }
    setSelectedIndex(index);
    setOpen(false);
    triggerClick(index);
    event.stopPropagation();
  };

  /**
   *
   * @param {Event} e
   */
  const handleButtonClicked = (e) => {
    triggerClick(selectedIndex);
    e.stopPropagation();
  };

  const triggerClick = (index) => {
    if (options[index].onClick) {
      options[index].onClick();
    } else {
      setOpen(true);
    }
  }

  /**
   *
   * @param {Event} e
   */
  const handleToggle = (e) => {
    setOpen((prevOpen) => !prevOpen);
    e.stopPropagation();
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const btnBackgroundColor = isSecurePickup ? classes.isSecurePickup : classes.isNotAllSecurePickup;

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
          disableElevation
          disabled={disabledBtn ? true : false}
        >
          <Button onClick={handleButtonClicked} className={classNames(classes.splitButton, btnBackgroundColor)}>
            {options[selectedIndex]?.label}
          </Button>
          <Button
            size="small"
            className={btnBackgroundColor}
            variant="contained"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          style={{ zIndex: 200 }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.filter(o => o.label !== 'Secure Pick-up').map((option, index) => (
                      <WithToolTip key={index} title={option.disabled ? option.tooltip : ''}>
                        <MenuItem
                        key={option.label}
                        disabled={option.disabled}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                        className={option.className}
                      >
                        {option.label}
                      </MenuItem>
                     </WithToolTip> 
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};