import { useEffect, useState } from 'react';

const useContainersOptions = (containersByBl, containerFlatView, doGrouping, drivers, barges, trains, user) => {
  const [state, setState] = useState({});

  const makeOptionsState = (containers) => {

    const isAssigned = ({ transferStatus }) => transferStatus === 'assigned';
    const isTransferred = ({ transferStatus }) => transferStatus === 'transferred';
    const isOwned = ({ transferStatus }) => transferStatus === 'owned';
    const isBlocked = ({ releaseStatus }) => releaseStatus === 'blocked';
    
    const hasDrivers = (drivers) => drivers.length > 0;
    const hasBarges = (barges) => barges.length > 0;
    const hasTrains = (trains) => trains.length > 0;

    const pcsAvailable = ({ ediPcs }) => ediPcs?.toLowerCase() === 'portbase' && user.isConnectedtoPortbase; // NOTE: there's room left here for other PCSs
    const isSentToPcs = ({ sentToPcs }) => sentToPcs === true;
 
    return {
      transfer: containers.some(isOwned) && !containers.every(isSentToPcs),
      revoke:   containers.some(isTransferred),
      pincode:  !containers.every(isTransferred),
      assign:   !containers.every(isTransferred) && (hasDrivers(drivers) || hasBarges(barges) || hasTrains(trains)) && !containers.every(isSentToPcs),
      unassign: containers.some(isAssigned) && (hasDrivers(drivers) || hasBarges(barges) || hasTrains(trains)) && !containers.every(isSentToPcs),
      block:    !containers.every(isBlocked),
      unblock:  containers.some(isBlocked),
      transferToPcs: containers.some(pcsAvailable) && !containers.every(isSentToPcs),
      revokeFromPcs: containers.some(pcsAvailable) && containers.some(isSentToPcs),
    };
  };

  useEffect(() => {
    const calculateContainersOptionState = () => {
      if(doGrouping){
        const arr = Object.entries(containersByBl);
        if (!arr.length) return;

        const optionsState = arr.reduce((acc, [bl, containers]) => {
          acc[bl] = makeOptionsState(containers);

          return acc;
        }, {});
        setState(optionsState);
      }else{
        const optionsForFlatViewContainers = makeOptionsState(containerFlatView)
        setState(optionsForFlatViewContainers);
      }
    };
    calculateContainersOptionState();
  }, [containersByBl, containerFlatView, doGrouping, drivers, barges, trains]);

  return state;
};

export default useContainersOptions;
