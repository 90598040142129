import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import useIntl from './useIntl';

const useTurnInHeaders = (setColumns) => {

	const [turnInLocationType, setTurnInLocationType] = useState('Truck');
	const { translate } = useIntl();

	const handleTurnInLocationTypeChange = (event) => {
    setTurnInLocationType(event.target.value);
  }

  useEffect(() => {
  	setColumns && setColumns();
  }, [turnInLocationType])

	const getTurnInLocationHeader = (type) => {
		return {
	  	name: translate(`releaseOverview.containers.columns.turnInLocation${type}`),
		  options: { 
		  	sort: false,
		    display: turnInLocationType === type, 
		    viewColumns: turnInLocationType === type,
		    filter: turnInLocationType === type,
		    customHeadLabelRender: (columnMeta) => {
		      return (
		        <Select
		          labelId="turnInLocation"
		          id="turnInLocation"
		          value={turnInLocationType}
		          onChange={handleTurnInLocationTypeChange}
		          style={{ color: '#cda24d', fontSize: '12px', fontWeight: 'bold' }}
		        >
		          <MenuItem value={'Truck'}>{translate('releaseOverview.containers.columns.turnInLocationTruck')}</MenuItem>
		          <MenuItem value={'Barge'}>{translate('releaseOverview.containers.columns.turnInLocationBarge')}</MenuItem>
		          <MenuItem value={'Rail'}>{translate('releaseOverview.containers.columns.turnInLocationRail')}</MenuItem>
		        </Select>
		      )
		    }
		  }
		}
	}

	const getTurnInReferenceHeader = (type) => {
		return {
	    name: translate(`releaseOverview.containers.columns.turnInReference${type}`),
	    options: { 
	      display: turnInLocationType === type,
	      viewColumns: turnInLocationType === type,
	      filter: turnInLocationType === type,
	    }
	  }
  }

  return { getTurnInLocationHeader, getTurnInReferenceHeader };

};

export default useTurnInHeaders;
