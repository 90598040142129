import React from 'react';
import { useHistory } from 'react-router-dom';
import { getConnectedOrganizations } from '../util/connections';
import { isTokenValid } from '../util/token';
import useIntl from './useIntl';
import useWalletError from './useWalletError';

export function useConnectedOrganizations() {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);

  const onWalletError = useWalletError();
  const history = useHistory();
  const { translate } = useIntl();

  const fetch = async () => {
    if (!isTokenValid) {
      setError(error);
      const error = new Error(translate('session.expired'));
      onWalletError(error);
      history.push('/');
      return;
    }
    const connectedOrganizations = await getConnectedOrganizations();
    setData(() => connectedOrganizations);
  };

  React.useEffect(() => {
    fetch();
  }, []);

  return { data, error, refetch: fetch };
}
