import React from 'react';
import client from '../../../feathers';
import useCarrierApiError from '../../carrier-ui/hooks/useCarrierApiError';

export function useDocumentTypes() {
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState([]);
  const onCarrierApiError = useCarrierApiError();

  const fetch = async () => {
    try {
      const documentsType = await client.service('document-types').find();
      setData(() => documentsType?.data);
    } catch (error) {
      console.error(error);
      setError(error);
      onCarrierApiError(error);
    }
  };

  React.useEffect(() => {
    fetch();
  }, []);

  return { data, error, refetch: fetch };
}
