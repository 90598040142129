import dateformat from 'dateformat';
import moment from 'moment-timezone';

export const formatDate = (date) => dateformat(date, 'dd mmm yyyy');
export const formatIsoDate = (date) => dateformat(date, 'isoDate');

export const formatDateTime = (date) => {
  if (!date) {
    return ''; // Return an empty string if the input date is empty
  }
  return dateformat(date, 'dd/mm/yyyy HH:MM:ss');
};

export const brusselsDateTime = (date) => {
  if (!date) {
    return ''; // Return an empty string if the input date is empty
  }

  // Create a Moment.js object and set the timezone to Brussels
  const brusselsMoment = moment(date).tz('Europe/Brussels');

  // Format the date and time
  return brusselsMoment.format('DD/MM/YYYY HH:mm:ss');
}
