import React from 'react';
import { Http } from '../../../axios';

export function useSecurePickupContainers() {
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState([]);

  const doSearch = async (querySearch) => {
    try {
      const response = await Http.get(`/terminal-user/secure-pickup-containers`, { params: { querySearch } });
      setData(() => response?.data);
    } catch (error) {
      setError(error);
    }
  };

  return { data, error, doSearch };
}
