import React, { useEffect, useState } from 'react';
import client from '../feathers';

export const useTrains = (query) => {
  const [state, setState] = useState([]);

  useEffect(() => {
    const fetchTrains = async (query) => {
      const result = await client.service('trains').find({ query });
      setState(result.data);
    };
    fetchTrains(query);
  }, []);

  return state;
};
