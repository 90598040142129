import React from 'react';
import useIntl from '../../hooks/useIntl';
import TMDialog from '../../lib/Dialog/TMDialog';
import { JsonForms } from '../JsonForms/JsonForms';

export function AddDocument({
  initialData,
  openDialog,
  setOpenDialog,
  handleSubmit,
  documentTypeId,
  readonly,
  uiSchema = {},
  schema = {},
  title = '',
  formDefinition = null,
}) {
  const [data, setData] = React.useState(initialData);
  const { translate } = useIntl();

  React.useEffect(() => setData(initialData), [initialData]);

  const createMarkup = (formDefinition) => {
    return { __html: formDefinition };
  };

  return (
    <TMDialog
      key="add document"
      title={title}
      dialogOpen={openDialog}
      handleDialogClose={() => setOpenDialog(false)}
      maxWidth="md"
      showBottomClose
      btnLabel={translate('documents.submit')}
      handleSubmit={() => handleSubmit({ ...data, documentTypeId })}
      actionBtnClasses="flex justify-space-between w-full"
      actionBtnVariant="contained"
      disableSubmit={readonly}
    >
      <div dangerouslySetInnerHTML={createMarkup(formDefinition)} />
      <JsonForms
        i18n={{ translate }}
        schema={schema}
        uischema={uiSchema}
        data={data}
        onChange={({ data }) => setData(data)}
        readonly={readonly}
      />
    </TMDialog>
  );
}
