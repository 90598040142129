import React from 'react';

import Grid from '@material-ui/core/Grid';
import TwoFactorAuthentication from '../components/TwoFactorAuthentication/TwoFactorAuthentication';

export default function TwoFactorAuthenticationPage() {
  return (
    <Grid container>
      <Grid item xs={12} lg={8} style={{ margin: '0 auto' }}>
        <TwoFactorAuthentication />
      </Grid>
    </Grid>
  );
}
