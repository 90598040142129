import React from 'react';
import {
  materialCells,
  materialRenderers,
} from '@jsonforms/material-renderers';

import { JsonForms as InternalJsonForms } from '@jsonforms/react';
import UploadFile, { uploadFileTester } from './customRenderers/upload/Upload';
import OutlinedTextField, {
  outlinedTextFieldTester,
} from './customRenderers/TextField/TextField';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import OutlinedDateField, {
  outlinedDateTester,
} from './customRenderers/DateField/DateField';

import OutlinedSelectField, {
  outlinedSelectFieldTester,
} from './customRenderers/Select/Select';

const renderers = [
  ...materialRenderers,
  //register custom renderers
  { tester: uploadFileTester, renderer: UploadFile },
  { tester: outlinedTextFieldTester, renderer: OutlinedTextField },
  { tester: outlinedDateTester, renderer: OutlinedDateField },
  { tester: outlinedSelectFieldTester, renderer: OutlinedSelectField },
];

const theme = createTheme({
  palette: {
    primary: {
      light: '#fcf4e3',
      main: '#cda24d',
      dark: '#c2823c',
    },
    secondary: {
      light: grey[300],
      main: grey[500],
      dark: grey[700],
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export function JsonForms(props) {
  return (
    <ThemeProvider theme={theme}>
      <InternalJsonForms
        {...props}
        renderers={renderers}
        cells={materialCells}
      />
    </ThemeProvider>
  );
}
