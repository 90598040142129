import React from 'react';
import { Http } from '../../../axios';

export function useAduditTrails() {
    const [data, setData] = React.useState([]);
    const [error, setError] = React.useState([]);

    const fetchAuditTrails = async (releaseAddress) => {
        try {
            if(!releaseAddress) return;
            const response = await Http.get(`/audit-trail/${releaseAddress}` );
            setData(() => response?.data);
        } catch (error) {
            setError(error);
        }
    };

    return { data, error, fetchAuditTrails };
}
