import client from '../feathers';
import logSentry from './logSentry'

export const addtoUserAuditTrail = (operation, description) => {
  try {
    client.service('user-audit-trail').create({ operation, description })
  } catch (e) {
    console.error('Could not insert in user-audit-trail');
    logSentry(e);
  }
};
