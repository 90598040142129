import React from 'react';
import Containers from './Containers';
import TMDialog from '../lib/Dialog/TMDialog';
import './style.css';
import TermsAndConditions from './TermsAndConditions';

const PincodeDialog = ({
  show,
  handleDialogClose,
  blNumber,
  selectedReleaseAddresses,
  showPincode,
  anonAddresses,
  containers,
  refreshBills,
  connectedOrganizations,
  connections
}) => {
  return (
    <TMDialog
      key={blNumber}
      fullScreen
      title={blNumber}
      dialogOpen={show}
      handleDialogClose={handleDialogClose}
      maxWidth="xl"
    >
      <Containers
        containers={containers}
        refreshBills={refreshBills}
        selectedReleaseAddresses={selectedReleaseAddresses} // currently used to fetch selected pincodes, not all pincodes from the entire BL
        showPincode={showPincode}
        handleDialogClose={handleDialogClose}
        selectableRows="none"
        connectedOrganizations={connectedOrganizations}
        connections={connections}
        anonAddresses={anonAddresses}
      />
      {blNumber && <TermsAndConditions blNumber={blNumber} anonAddresses={anonAddresses}/>}
    </TMDialog>
  );
};

export default PincodeDialog;
