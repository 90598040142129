// import React from 'react';
import { getFlatExcelWorkbook } from '../util/excel';
import { saveAs } from 'file-saver';
import { getPincode, filterUnavailableReleases, filterPinAllowedLocations, filterAssignLocationsReleases } from '../util/pincode';
import useAuth from './useAuth';
import useIntl from './useIntl';
import useWalletError from './useWalletError';
import { checkAssignLocations } from '../services/releases';
// import useSentry from '../hooks/useSentry';

const useFlatExcel = (anonAddresses) => {
  const { user } = useAuth();
  const { translate } = useIntl();
  const onWalletError = useWalletError();
  // const logSentry = useSentry();

  /**
   * Download given excel workbook
   * @param {excel} workbook
   */
  const download = async (workbook) => {
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), 'Releases.xlsx');
  };

  /**
   * flatten bill numbers
   * @param {Array[objects]} bill
   */
  const formatReleases = (bill) => {
    return bill.reduce(
      (result, { releases, ...bill }) =>
        result.concat(
          releases.map((release) => ({
            ...bill,
            ...release,
            releaseStatus: translate(`releaseStatus.${release.releaseStatus}`),
          }))
        ),
      []
    );
  };

  /**
   * Fetch pincodes and add pincode to each release
   * @param {Array of releases} releases
   */
  const addPincodeToReleases = async (releases, userCanFetchPincode) => {    
    const assignLocationsCheckResult = await checkAssignLocations({ releaseAddresses: releases.map(r => r.address), anonAddresses });
    
    // console.log("2 assignLocationsCheckResult", assignLocationsCheckResult)
    // console.log("2 releases", releases)


    let getPincodesForReleases = releases
      .filter(r => filterUnavailableReleases(r))
      .filter(r => filterPinAllowedLocations(assignLocationsCheckResult, r))
      .filter(r => filterAssignLocationsReleases(assignLocationsCheckResult, r, userCanFetchPincode))
      .map(({address, version}) => ({address, version}));

    let pincodeResults = await getPincode(getPincodesForReleases, user.organization);
    
    console.log("-> releases", releases)
    console.log("-> getPincodesForReleases", getPincodesForReleases)
    console.log("-> pincodeResults 1", pincodeResults)

    pincodeResults = pincodeResults
      .concat(
        releases
          .filter(r => !filterUnavailableReleases(r))
          .map(r => ({ address: r.address, error: 'Not allowed to fetch pincode'}))
      )
      .concat(
        releases
          .filter(r => filterUnavailableReleases(r))
          .filter(r => !filterPinAllowedLocations(assignLocationsCheckResult, r))
          .map(r => ({ address: r.address, error: 'Pins disabled'}))
      )
      .concat(
        releases
          .filter(r => filterUnavailableReleases(r))
          .filter(r => filterPinAllowedLocations(assignLocationsCheckResult, r))
          .filter(r => !filterAssignLocationsReleases(assignLocationsCheckResult, r, userCanFetchPincode))
          .map(r => ({ address: r.address, error: (r.isSecurePickup && !userCanFetchPincode) ? 'Not allowed to fetch pincode' : 'Must assign to fetch pin'}))
      )

    console.log("-> pincodeResults 2", pincodeResults)

    return releases.map(release => { 
      let result = pincodeResults.find(res => res.address === release.address)
      console.log("-> result", result)
      release.pincode = result ? (result.pincode ? result.pincode : result.error) : undefined;
      console.log("-> release.pincode", release.pincode)
      return release;
    })
  };

  /**
   * It downloads releases in flat table format
   * @param {Array} data - data to be downloaded
   * @param {Boolean} fetchPincode
   */
  const flatExcel = async (data, fetchPincode, userCanFetchPincode) => {
    // console.log("1 data", releases)
    let releases = formatReleases(data);
    // console.log("1 releases", releases)
    if (fetchPincode) {
      try {
        releases = await addPincodeToReleases(releases, userCanFetchPincode);
      } catch (e) {
        onWalletError(translate(e.message));
      }
    }

    await download(getFlatExcelWorkbook(releases, fetchPincode));
  };

  return flatExcel;
};

export default useFlatExcel;
