import types from './types';
import { types as NMoTTypes } from '../NMoT/types'

export const initialState = {
  uploadedContainers: [],
  anonAddresses: [],
  eligible: [],
  notFound: [],
  pincodeFetched: [],
  transferred: [],
  pickedUp: [],
  deleted: [],
  expired: [],
  total: 0,
  totalErrors: 0,
  error: [],
  warning: [],
  transferDialog: false,
  assignDialog: false,
  selectedReleases: [],
  bulkValidationErrors: '',
  form: {
    excelName: '',
  },
  NMoT: {
    dialog: false,
    selected: ''
  }
};

export const reducer = (state, action) => {
  switch (action.type) {
    case types.SET_UPLOADED_CONTAINERS:
      return { ...state, uploadedContainers: action.payload };

    case types.SET_ANON_ADDRESSES:
      return { ...state, anonAddresses: action.payload };

    case types.SET_FILTERED_CONTAINERS:
      return { ...state, ...action.payload };

    case types.SET_TOTAL:
      return { ...state, total: action.payload };

    case types.SET_TOTAL_ERRORS:
      return { ...state, totalErrors: action.payload };

    case types.SET_TRANSFER_DIALOG:
      return { ...state, transferDialog: action.payload };

    case types.SET_ASSIGN_DIALOG:
      return { ...state, assignDialog: action.payload };

    case types.SET_SELECTED_RELEASES:
      return { ...state, selectedReleases: action.payload };

    case types.SET_BULK_VALIDATION_ERRORS:
      return { ...state, bulkValidationErrors: action.payload };

    case types.SET_FORM_VALUES:
      return { ...state, form: { ...state.form, ...action.payload } };

    case types.SET_ERROR:
      return { ...state, error: action.payload };
    
    case types.SET_WARNING:
      return { ...state, warning: action.payload };

    case NMoTTypes.SHOW_NMoT:
      return {...state, NMoT: {dialog: true}}
    
    case NMoTTypes.CANCEL_NMoT:
      return {...state, NMoT: {selected: '', dialog: false}}
    
    case NMoTTypes.SET_NMoT:
      return {...state, NMoT: {...state.NMoT, selected: action.payload}}

    case types.RESET_FORM:
      return { 
        uploadedContainers: [],
        anonAddresses: [],
        eligible: [],
        notFound: [],
        pincodeFetched: [],
        transferred: [],
        pickedUp: [],
        deleted: [],
        expired: [],
        total: 0,
        totalErrors: 0,
        error: [],
        warning: [],
        selectedReleases: [],
        bulkValidationErrors: '',
        form: {
          excelName: '',
        },
        NMoT: {
          dialog: false,
          selected: ''
        }
      };

    default:
      return { ...state };
  }
};
