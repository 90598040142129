import React from 'react'
import useIntl from '../hooks/useIntl'

const PcsActionReport = ({ data, pcsOpType }) => {
  // console.log("data", data)
  const { translate } = useIntl()

  return (
    <div>
      <p>{translate(`pcsActionReport.summary`)}</p>
      {
        Object.entries(data).map(([key, value]) => {
          return value.length 
          ? (
              <div key={key}>
              { translate(`pcsActionReport.${pcsOpType}.${key}`) }
                <ul>
                  { value.map((result, i) => 
                    <li key={i}>{result.containerNumber} {result.ediPcs} {key==='errors' && `: ${result.error}`}</li>
                  )}
                </ul>
              </div>
            ) 
          : null
        })
      }
    </div>
  )
}

export default PcsActionReport