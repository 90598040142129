import React from 'react';

import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, isControl, isEnumControl } from '@jsonforms/core';
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core';

import { isEmpty } from 'lodash';

function OutlinedSelectField(props) {
  const { schema, path, handleChange, data, uischema, label, enabled } = props;
  const { readOnly, inputProps = {}, defaultValue = '' } = uischema.options;
  const id = `${path}-input`;

  return (
    <FormControl variant="outlined" fullWidth margin="normal">
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        value={data ?? defaultValue}
        onChange={(event) => {
          handleChange(path, event.target.value);
        }}
        input={
          <OutlinedInput
            label={label}
            id={id}
            disabled={readOnly || !enabled}
            inputProps={{ inputProps }}
          />
        }
      >
        {schema.enum.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export const tester = () => (uischema, schema, context) => {
  const { options } = uischema;
  if (
    isEmpty(options) ||
    !isControl(uischema) ||
    !isEnumControl(uischema, schema, context)
  ) {
    return false;
  }

  return (
    options?.variant === 'outlined' && isEnumControl(uischema, schema, context)
  );
};

export const outlinedSelectFieldTester = rankWith(
  3, //increase rank as needed
  tester()
);

export default withJsonFormsControlProps(OutlinedSelectField);
