import React from 'react';

import TextField from '@material-ui/core/TextField';
import { withJsonFormsControlProps } from '@jsonforms/react';
import { rankWith, isControl, isEnumControl } from '@jsonforms/core';

import { isEmpty } from 'lodash';

function OutlinedTextField(props) {
  const { label, uischema, path, handleChange, data, enabled, schema } = props;
  const { options } = uischema;
  const { readOnly, multi, rows, inputProps = {}, defaultValue = '' } = options;

  const type = schema.type || options.type;

  const validateNumber = (newValue) => {
    const min = inputProps.min ?? Number.MIN_VALUE;
    if (Number.isNaN(newValue)) return min;

    return newValue >= min ? newValue : min;
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      fullWidth
      value={data ?? defaultValue}
      onChange={(event) =>
        handleChange(
          path,
          type === 'number'
            ? validateNumber(event.target.valueAsNumber)
            : event.target.value
        )
      }
      disabled={readOnly || !enabled}
      multiline={multi}
      type={type}
      rows={rows}
      margin="normal"
      InputProps={{ inputProps }}
    />
  );
}

export const tester = () => (uischema, schema, context) => {
  const { options } = uischema;
  if (isEmpty(options) || !isControl(uischema)) {
    return false;
  }

  return (
    options?.variant === 'outlined' && !isEnumControl(uischema, schema, context)
  );
};

export const outlinedTextFieldTester = rankWith(
  3, //increase rank as needed
  tester()
);

export default withJsonFormsControlProps(OutlinedTextField);
