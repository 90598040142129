import '../style.css';

import React from 'react';
import TMDataTable from '../../lib/Table/TMDataTable'; import useIntl from '../../hooks/useIntl';
import { brusselsDateTime } from '../../util/date';
import { useAduditTrails } from './hooks/useAuditTrail';

export function AuditTrails({ releaseAddress }) {
  const { data = [], fetchAuditTrails } = useAduditTrails();
  const { translate } = useIntl();

  React.useEffect(() => {
    fetchAuditTrails(releaseAddress);
  }, [releaseAddress])


  const columns = [
    {
      name: 'details',
      label: 'details',
    },
    {
      name: 'operation',
      label: 'operation',
    },
    {
      name: 'operationAt',
      label: 'operationAt',
      options: {
        customBodyRender: (date) => brusselsDateTime(date),
      },
    },
  ].map(({ label, ...rest }) => ({
    label: translate(`auditTrails.${label}`),
    ...rest,
  }));

  const options = {
    print: false,
    search: false,
    download: true,
    filter: false,
    viewColumns: false,
    downloadOptions: { 
      filename: 'audit-trails.csv',
  },
    textLabels: {
      body: {
        noMatch: translate('dataTable.controls.noMatch'),
      },
    },
  };

  if(!releaseAddress) return null;

  return (
    <div className="no-radius-table">
      <TMDataTable className='pl-16 pr-16' title='Audit trail' columns={columns} data={data} options={options} />
    </div>
  );
}
